import React, { useState } from 'react';
import { Button, ButtonGroup } from '@mui/joy';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BaseLoadProfileDto, LoadProfileDto, HeatPumpLoadProfileDto, LoadProfileType } from '../pv-calculator.dto';
import HeatPumpLoad from './HeatPumpLoad';
import CustomLoad from './CustomLoad';
import BaseLoad from './BaseLoad';

export default function LoadProfilesContainer(props: { value: LoadProfileDto[], onChange: (loadProfiles: LoadProfileDto[]) => void }) {
    const { t } = useTranslation();

    const [loadProfiles, setLoadProfiles] = useState<LoadProfileDto[]>(props.value);

    const addLoadProfile = (type: LoadProfileType) => {
        let newProfile: LoadProfileDto;
        switch (type) {
            case LoadProfileType.BASE:
                newProfile = {
                    type,
                } as BaseLoadProfileDto;
                break;
            case LoadProfileType.HEAT_PUMP:
                newProfile = {
                    type,
                    annual_heat_demand: 0,
                    annual_cop: 0,
                } as HeatPumpLoadProfileDto;
                break;
            case LoadProfileType.CUSTOM:
                newProfile = {
                    type,
                    values: [],
                };
                break;
        }

        setLoadProfiles([...loadProfiles, newProfile]);
    };
    const deleteLoadProfile = (i: number) => {
        loadProfiles.splice(i, 1);
        setLoadProfiles([...loadProfiles]);
        props.onChange(loadProfiles);
    };
    const updateLoadProfile = (i: number, newProfile: LoadProfileDto) => {
        loadProfiles[i] = newProfile;
        setLoadProfiles([...loadProfiles]);
        props.onChange(loadProfiles);
    };

    return (
        <>
            <ButtonGroup color="neutral" variant="solid" size="sm">
                <Button startDecorator={<Add />} onClick={() => addLoadProfile(LoadProfileType.BASE)}>
                    {t('Base usage')}
                </Button>
                <Button startDecorator={<Add />} onClick={() => addLoadProfile(LoadProfileType.HEAT_PUMP)}>
                    {t('Heat pump')}
                </Button>
                <Button startDecorator={<Add />} onClick={() => addLoadProfile(LoadProfileType.CUSTOM)}>
                    {t('From CSV')}
                </Button>
            </ButtonGroup>

            {loadProfiles.map((loadProfile, i) => (
                <React.Fragment key={i}>
                    {loadProfile.type === LoadProfileType.BASE && (
                        <BaseLoad
                            onChange={(changedProfile) => updateLoadProfile(i, changedProfile)}
                            onDelete={() => deleteLoadProfile(i)}
                        />
                    )}

                    {loadProfile.type === LoadProfileType.HEAT_PUMP && (
                        <HeatPumpLoad
                            onChange={(changedProfile) => updateLoadProfile(i, changedProfile)}
                            onDelete={() => deleteLoadProfile(i)}
                        />
                    )}

                    {loadProfile.type === LoadProfileType.CUSTOM && (
                        <CustomLoad
                            onChange={(changedProfile) => updateLoadProfile(i, changedProfile)}
                            onDelete={() => deleteLoadProfile(i)}
                        />
                    )}
                </React.Fragment>
            ))}
        </>
    );
}
