import { httpClient } from '../../infrastructure/admi-client/http-client';
import { API_BASE_URL } from '../../appConfig';
import { OnsiteDataResponseDto, OnsiteListResponseDto, OnsiteSearchDto } from './onsite.dto';

const ADMI_BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export const admiOnsiteService = {
    getOnsiteList: async (search: OnsiteSearchDto): Promise<OnsiteListResponseDto> => {
        const response = await httpClient.get<OnsiteListResponseDto>(
            `${ADMI_BASE_URL}/api/v1/onsite?page=${search.page}`,
        );
        return response.data;
    },

    getOnsite: async (reference: string): Promise<OnsiteDataResponseDto> => {
        const response = await httpClient.get<OnsiteDataResponseDto>(`${ADMI_BASE_URL}/api/v1/onsite/${reference}`);
        return response.data;
    },
};
