import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

/**
 * Isomorphic httpInstance
 * @param baseUrl
 */
export const httpInstance = <T>(baseUrl: string) => {
    const requestOptions: AxiosRequestConfig = {
        baseURL: baseUrl,
        headers: {
            'Authorization': `Bearer ${Cookies.get('CF_Authorization')}`, // Add the cookie value
            'Content-Type': 'application/json',
          }, 
    };

    const instance: AxiosInstance = axios.create(requestOptions);

    instance.interceptors.request.use(
        (request) => request,
        (requestError) => Promise.reject(requestError),
    );

    instance.interceptors.response.use(
        (response: AxiosResponse<T>) => response,
        (responseError: AxiosError<T>) => {
            console.warn('Admi response error', responseError);

            if (responseError.response?.status !== undefined && [401, 403].includes(responseError.response?.status)) {
                return Promise.reject(responseError);
            } else {
                if (responseError.response) {
                    throw responseError.response.data;
                } else {
                    return Promise.reject(responseError);
                }
            }
        },
    );

    return instance;
};
