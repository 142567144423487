import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { featureFlags } from './features/feature-flags/feature-flags.reducer';
import { tagging } from './features/tagging/tagging.reducer';
import { tagger } from './features/global-tagger/global-tagger.reducer';
import { onsite } from './features/onsite/onsite.reducer';
import { pvAnalysis } from 'features/pv-analysis/pv-analysis.reducer';

const reducer = combineReducers({
    featureFlags,
    tagging,
    tagger,
    onsite,
    pvAnalysis,
});

const store = configureStore({
    reducer,
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export default store;
