import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { httpInstance } from './http-instance';
import { API_BASE_URL } from '../../appConfig';

const BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export const httpClient = {
    /**
     * Isomorphic get request
     * @param url
     * @param config
     */
    async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .get<T>(url, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic delete request
     * @param url
     * @param config
     */
    async delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .delete<T>(url, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic head request
     * @param url
     * @param config
     */
    async head<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .head<T>(url, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic options request
     * @param url
     * @param config
     */
    async options<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .options<T>(url, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic post request
     * @param url
     * @param data
     * @param config
     */
    async post<T>(url: string, data = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .post<T>(url, data, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic put request
     * @param url
     * @param data
     * @param config
     */
    async put<T>(url: string, data = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .put<T>(url, data, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
    /**
     * Isomorphic patch request
     * @param url
     * @param data
     * @param config
     */
    async patch<T>(url: string, data = {}, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await httpInstance(BASE_URL)
            .patch<T>(url, data, config)
            .then((response) => Promise.resolve(response))
            .catch((error) => Promise.reject(error));
    },
};
