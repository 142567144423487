import { Button, Grid, Input } from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SearchIcon from '@mui/icons-material/Search';
import { resetBuildingSelection, setLocation } from "../pv-analysis.actions";
import { useAppDispatch, useAppSelector } from "redux-app-hooks";
import useGoogleMapsApiLoader from "infrastructure/google/useGoogleMapsApiLoader";

export default function Search() {
    const { location, isLoading } = useAppSelector(state => state.pvAnalysis);
    const dispatch = useAppDispatch();

    const [address, setAddress] = useState('');
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const loader = useGoogleMapsApiLoader();

    const { t } = useTranslation();

    useEffect(() => {
        console.debug('Search', location);
        loadGeocoder();

        console.debug('NODE ENV', process.env.NODE_ENV);
    }, []);

    useEffect(() => {
        setAddress(location.address);
        dispatch(resetBuildingSelection());
    }, [location]);

    async function loadGeocoder() {
        const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
        setGeocoder(new Geocoder());
    }

    async function handleChangeAddress(e: any) {
        console.debug('handleChangeAddress', address, location.address);

        if ((e.key === 'Enter' || e.type === 'click') && geocoder !== undefined) {
            const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({ address });
            console.debug('address.geocoderResponse', geocoderResponse);

            if (geocoderResponse.results.length > 0) {
                dispatch(setLocation({
                    lat: geocoderResponse.results[0].geometry.location.lat(),
                    lng: geocoderResponse.results[0].geometry.location.lng(),
                    address: geocoderResponse.results[0].formatted_address,
                }));
            }
        }
    }

    return (
        <Grid xs={12} container direction="row" spacing={3}>
            <Grid xs={8}>
                <Input
                    placeholder={t('Search')}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyDown={(e) => handleChangeAddress(e)}
                    startDecorator={<SearchIcon />}
                    sx={{ flexGrow: 1 }}
                    disabled={isLoading}
                />
            </Grid>
            <Grid xs={4}>
                <Button variant="solid" color="primary" onClick={(e) => handleChangeAddress(e)} disabled={isLoading}>
                    {t('Search')}
                </Button>
            </Grid>
        </Grid>
    );
}
