import { Position } from 'geojson';
import {
    LocationSearch,
    PVAnalysisDto,
    PVAnalysisSearchDto,
    PVAnalysisVariantDto,
    RealEstate,
} from './pv-analysis.dto';
import { PaginatedResponseDto } from 'infrastructure/admi-client/response.dto';
import { httpClient } from 'infrastructure/admi-client/http-client';

export const admiPVAnalysisService = {
    getPVAnalysisList: async (search: PVAnalysisSearchDto): Promise<PaginatedResponseDto<PVAnalysisDto>> => {
        const response = await httpClient.get<PaginatedResponseDto<PVAnalysisDto>>(
            `/api/v1/pv-analysis?page=${search.page}`,
        );

        return response.data;
    },

    getPVAnalysisVariants: async (
        uuid: string,
        search: PVAnalysisSearchDto,
    ): Promise<PaginatedResponseDto<PVAnalysisVariantDto>> => {
        const response = await httpClient.get<PaginatedResponseDto<PVAnalysisVariantDto>>(
            `/api/v1/pv-analysis/${uuid}/variations?page=${search.page}&perPage=${search.perPage}`,
        );

        return response.data;
    },

    async loadRealEstateData(dataSearch: LocationSearch): Promise<RealEstate> {
        const response = await httpClient.get<RealEstate>('/api/v1/buildings', {
            params: {
                lat: dataSearch.location.lat,
                lng: dataSearch.location.lng,
                radius: dataSearch.radius,
                layers: dataSearch.layers,
                settings: {
                    maxSystemCapacityAdjustment: dataSearch.maxSystemCapacityAdjustment,
                }
            },
        });

        return response.data;
    },

    async createPVAnalysisVariant(
        name: string,
        description: string,
        mapCenter: Position,
        mapZoom: number,
        mapScreenshotImageData: Blob,
        pvAnalysisUuid?: string,
    ): Promise<PVAnalysisVariantDto> {
        // Post as multipart/form-data
        const formData = new FormData();
        if (pvAnalysisUuid) {
            formData.append('pvAnalysisUuid', pvAnalysisUuid);
        }
        formData.append('name', name);
        formData.append('description', description);
        formData.append('mapCenter', JSON.stringify(mapCenter));
        formData.append('mapZoom', mapZoom.toString());
        formData.append('mapImageData', mapScreenshotImageData);

        const response = await httpClient.post<PVAnalysisVariantDto>('/api/v1/pv-analysis/variations', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    },
};
