import React, { useEffect } from 'react';
import { Box, FormControl, FormLabel, Typography, Input, Switch } from '@mui/joy';
import { formCheckbox, formFieldBuilder } from '../../common/formikUtils';
import { connect } from 'react-redux';
import { OnsiteDataPoint } from 'features/onsite/onsite.dto';
import { asGermanDate } from 'common/date';
import { ifNotNull } from 'common/helpers';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    formik: any;
}

function FragmentAllgemein(props: Properties) {
    const { formData, formik } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    useEffect(() => {
        ifNotNull(formData['building-name'], (value) => formik.setFieldValue('GEBAUDENAME', value['response']));
        ifNotNull(formData['address'], (value) => formik.setFieldValue('ADRESSE', value['response']));
        ifNotNull(formData['postal-code'], (value) => formik.setFieldValue('PLZ', value['response']));
        ifNotNull(formData['municipality'], (value) => formik.setFieldValue('KOMMUNE', value['response']));
        ifNotNull(formData['implementation-date'], (value) => formik.setFieldValue('ENDDATUM_UMSETZUNG', asGermanDate(value['response'])));
        ifNotNull(formData['is-funded'], (value) => formik.setFieldValue('GEFOERDERT', value['response'] === 'Ja'));
    }, [formData]);

    return (
        <>
            <FormControl required>
                <FormLabel>Gebäudename</FormLabel>
                <Input placeholder="Gebäudename" {...formField('GEBAUDENAME')} />
            </FormControl>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Adresse</FormLabel>
                    <Input placeholder="Straße und Hausnummer" {...formField('ADRESSE')} />
                </FormControl>

                <FormControl required>
                    <FormLabel>PLZ</FormLabel>
                    <Input placeholder="PLZ" {...formField('PLZ')} />
                </FormControl>
            </Box>

            <FormControl required>
                <FormLabel>Name der Kommune</FormLabel>
                <Input placeholder="Name der Kommune" {...formField('KOMMUNE')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Abschlussdatum</FormLabel>
                <Input type="date" placeholder="Abschlussdatum" {...formField('ENDDATUM_UMSETZUNG')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Zeitrahmen Umsetzung</FormLabel>
                <Input type="text" placeholder="Zeitrahmen Umsetzung" {...formField('ZEITRAHMEN_UMSETZUNG')} />
            </FormControl>

            <Typography
                component="label"
                startDecorator={<Switch sx={{ ml: 1 }} {...formCheckbox(formik, 'GEFOERDERT')} />}
            >
                Gefördertes Projekt
            </Typography>

            <FormControl required>
                <Typography level="h3">Allgemein</Typography>

                <FormLabel>Dachfläche Potenzial (in m²)</FormLabel>
                <Input
                    type="number"
                    placeholder="Dachfläche Potenzial (in m²)"
                    {...formField('DACHFLAECHE_POTENZIAL')}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Dachflächen</FormLabel>
                <Input
                    type="number"
                    placeholder="Anzahl Dachflächen"
                    slotProps={{ input: { min: 1 } }}
                    {...formField('ANZAHL_DACHFLAECHEN')}
                />
            </FormControl>

            <FormControl required>
                <FormLabel>Größe der Anlage/Spitzenleistung (in kWp)</FormLabel>
                <Input type="number" placeholder="Größe der Anlage (in kWp)" {...formField('PEAK_LEISTUNG')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Batteriekapazität (in kWh)</FormLabel>
                <Input type="number" placeholder="Batteriekapazität (in kWh)" {...formField('BATTERIE_KAPAZITAET')} />
            </FormControl>
        </>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(FragmentAllgemein);
