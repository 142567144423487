import Table from '@mui/joy/Table';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { DataSearch, Filter, Location, Row, RowConfig } from '../tagging.dto';
import { changeRowHighlight, loadPVData } from '../tagging.actions';
import TagInput from './TagInput';
import { CircularProgress } from '@mui/joy';

interface Properties {
    location: Location;
    filter: Filter;
    rows: Row[];
    rowConfig: RowConfig[];
    selectedTag: string;
    isLoading: boolean;
    loadPVData: any;
    changeRowHighlight: any;
}

function TaggingTable(props: Properties) {
    const { location, filter, rows, rowConfig, selectedTag, isLoading, loadPVData, changeRowHighlight } = props;

    useEffect(() => {
        console.debug('TaggingTable.location changed', location, filter, rows);
        loadPVData({ location, filter });
    }, [location, filter]);

    function loadHeader(): any[] {
        if (rows !== undefined && rows.length > 0) {
            return Object.keys(rows[0].data)
                .filter((key) => !['geometry', 'tag'].includes(key))
                .map((key) => <th key={key}>{key}</th>);
        }

        return [];
    }

    /**
     * Loads the rows
     *  - filters the rows by selectedTag
     *  - maps the rows to a <tr>
     *
     * @param rows
     */
    function loadRows(rows: Row[]): any[] {
        const trs: any[] = [];

        for (let index = 0; index < rows.length; index++) {
            const row = rows[index];

            if (selectedTag !== '' && row.data.tag !== selectedTag) {
                continue;
            }

            trs.push(
                <tr
                    key={'row' + index}
                    style={getBackgroundColor(row, index)}
                    onMouseOver={() => changeRowHighlight(index, true)}
                    onMouseOut={() => changeRowHighlight(index, false)}
                >
                    <td>
                        <TagInput row={row} />
                    </td>
                    {Object.keys(row.data)
                        .filter((key) => !['geometry', 'tag'].includes(key))
                        .map((key) => (
                            <td key={key}>{row.data[key]}</td>
                        ))}
                </tr>,
            );
        }

        return trs;
    }

    function getBackgroundColor(row: Row, index: number): object {
        return {
            backgroundColor: rowConfig[index] && rowConfig[index].isHighlighted ? '#' + row.color : 'transparent',
        };
    }

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Fragment>
                    {rows !== undefined && rows.length > 0 && (
                        <Table className="data">
                            <thead>
                                <tr>
                                    <th>TAG</th>
                                    {loadHeader()}
                                </tr>
                            </thead>

                            <tbody>{loadRows(rows)}</tbody>
                        </Table>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagging.location,
        filter: state.tagging.filter,
        rows: state.tagging.rows,
        rowConfig: state.tagging.rowConfig,
        selectedTag: state.tagging.selectedTag,
        isLoading: state.tagging.isLoading,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        loadPVData: (dataSearch: DataSearch) => dispatch(loadPVData(dataSearch)),
        changeRowHighlight: (index: number, isHighlighted: boolean) =>
            dispatch(changeRowHighlight(index, isHighlighted)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaggingTable);
