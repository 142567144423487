import { Dispatch } from "redux";
import { Building, GeoLocation, LocationSearch, RealEstate, RoofSegment } from "./pv-analysis.dto";
import { admiPVAnalysisService } from "./admi-pv-analysis.service";

export const TYPES = {
    SET_LOCATION: 'SET_LOCATION',

    LOAD_REAL_ESTATE_DATA: 'LOAD_REAL_ESTATE_DATA',

    LOAD_REAL_ESTATE_DATA_REQUESTED: 'LOAD_REAL_ESTATE_DATA_REQUESTED',
    LOAD_REAL_ESTATE_DATA_SUCCEEDED: 'LOAD_REAL_ESTATE_DATA_SUCCEEDED',
    LOAD_REAL_ESTATE_DATA_FAILED: 'LOAD_REAL_ESTATE_DATA_FAILED',

    SET_HIGHLIGHTED_BUILDING: 'SET_HIGHLIGHTED_BUILDING',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',

    ADD_BUILDING_TO_SELECTION: 'ADD_BUILDING_TO_SELECTION',
    REMOVE_BUILDING_FROM_SELECTION: 'REMOVE_BUILDING_FROM_SELECTION',
    REMOVE_ROOF_SEGMENT_FROM_BUILDING_SELECTION: 'REMOVE_ROOF_SEGMENT_FROM_BUILDING_SELECTION',
    RESET_BUIDING_SELECTION: 'RESET_BUIDING_SELECTION',

    TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS: 'TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS',

    SET_MAX_INSTALATION_CAPACITY: 'SET_MAX_INSTALATION_CAPACITY',
};

export const loadRealEstateData = (dataSearch: LocationSearch) => async (dispatch: Dispatch) => {
    dispatch(loadRealEstateDataRequested(dataSearch));
    console.debug('loadRealEstateDataRequested.dataSearch', dataSearch);

    try {
        const realEstate = await admiPVAnalysisService.loadRealEstateData(dataSearch);
        dispatch(loadRealEstateDataSucceeded(dataSearch, realEstate));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        console.error('loadRealEstateDataFailed.error', e, errorMessage);

        dispatch(loadRealEstateDataFailed(dataSearch, errorMessage));
    }
};

export function loadRealEstateDataRequested(dataSearch: LocationSearch) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED, dataSearch };
}

export function loadRealEstateDataSucceeded(dataSearch: LocationSearch, realEstate: RealEstate) {
    return {
        type: TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED,
        dataSearch,
        realEstate,
    };
}

export function loadRealEstateDataFailed(dataSearch: LocationSearch, errorMessage: string) {
    return { type: TYPES.LOAD_REAL_ESTATE_DATA_FAILED, dataSearch, errorMessage };
}

export function setLocation(location: GeoLocation) {
    return { type: TYPES.SET_LOCATION, location };
}

export function resetBuildingSelection() {
    return { type: TYPES.RESET_BUIDING_SELECTION };
}

export function setErrorMessage(errorMessage: string) {
    return { type: TYPES.SET_ERROR_MESSAGE, errorMessage };
}

export function toggleShowOnlySelectedBuildings() {
    return { type: TYPES.TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS };
}

export function addBuildingToSelection(selectedBuilding: Building) {
    return { type: TYPES.ADD_BUILDING_TO_SELECTION, selectedBuilding };
}

export function removeBuildingFromSelection(selectedBuilding: Building) {
    return { type: TYPES.REMOVE_BUILDING_FROM_SELECTION, selectedBuilding };
}

export function removeRoofSegmentFromBuildingSelection(selectedBuilding: Building, roofSegment: RoofSegment) {
    return { type: TYPES.REMOVE_ROOF_SEGMENT_FROM_BUILDING_SELECTION, selectedBuilding, roofSegment };
}

export function setHighlightedBuilding(highlightedBuilding: string) {
    return { type: TYPES.SET_HIGHLIGHTED_BUILDING, highlightedBuilding };
}

export function setMaxInstalationCapacity(maxInstalationCapacity: number) {
    return { type: TYPES.SET_MAX_INSTALATION_CAPACITY, maxInstalationCapacity };
}
