import { Alert, CircularProgress, FormControl, FormLabel, Grid, IconButton, Input, Typography } from "@mui/joy";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Building, RoofSegment } from "../pv-analysis.dto";
import { useTranslation } from "react-i18next";
import { formFieldBuilder } from "common/formikUtils";
import { Delete } from "@mui/icons-material";
import { removeBuildingFromSelection, removeRoofSegmentFromBuildingSelection } from "../pv-analysis.actions";
import { Dispatch } from "redux";

interface Properties {
    selectedBuildings: Building[],
    isLoading: boolean,
    formik: any,
    removeBuildingFromSelection: any,
    removeRoofSegmentFromBuildingSelection: any,
}

function SelectedBuildings(props: Properties) {
    const {
        selectedBuildings,
        isLoading,
        formik,
        removeBuildingFromSelection,
        removeRoofSegmentFromBuildingSelection,
    } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    const { t } = useTranslation();

    useEffect(() => {
        loadBuildings();
    }, [selectedBuildings]);

    function loadBuildings() {
        for (const [buildingIndex, building] of selectedBuildings.entries()) {
            for (const [roofIndex, roofSegment] of building.roof.roofSegments.entries()) {
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].module_area`, ((roofSegment.roofSegmentSolarPotential.panelHeightMeters * roofSegment.roofSegmentSolarPotential.panelWidthMeters) * roofSegment.roofSegmentSolarPotential.panelsCount).toFixed(2));
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].direction`, roofSegment.direction360Degrees.toFixed(2));
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].kwp`, roofSegment.roofSegmentSolarPotential.normalizedKwp.toFixed(2));
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].kwh_per_kwp`, roofSegment.roofSegmentSolarPotential.kwhPerKwp.toFixed(2));
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].pitch`, roofSegment.pitchDegrees.toFixed(2));
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].building_id`, building.code);
                formik.setFieldValue(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].center`, roofSegment.center);
            }
        }
    }

    function getRoofIndex(buldingIndex: number, roofIndex: number) {
        return (buldingIndex * selectedBuildings.length) + roofIndex;
    }

    return (
        <Grid xs={12} container>
            {isLoading && <CircularProgress />}
            {selectedBuildings.length === 0 && <Grid xs={12}><Alert>{t('Please, select or add a building')}.</Alert></Grid>}

            <Grid xs={12} container spacing={3}>
                {selectedBuildings.map((building: Building, buildingIndex: number) => (
                    <Grid key={building.code} xs={12} container>
                        <Grid xs={12} direction="row" container>
                            <Grid xs={11.5}><Typography level="h3">{t('Building')} #{buildingIndex+1} ({building.code})</Typography></Grid>
                            <Grid xs={0.5}><IconButton variant="soft" aria-label={t('Remove building from this PV Analysis')} onClick={() => removeBuildingFromSelection(building)}><Delete /></IconButton></Grid>
                        </Grid>

                        <Grid xs={12} direction="row" container>
                            <Grid xs={2}><b>{t('Name')}:</b></Grid><Grid xs={4}>{building.name || 'N/a'}</Grid>
                            <Grid xs={2}><b>{t('Building Function')}:</b></Grid><Grid xs={4}>{building.buildingFunction.name}</Grid>
                        </Grid>

                        {building.roof.roofSegments.map((roofSegment, roofIndex) => (
                            <Grid xs={12} direction="row" container key={roofSegment.code}>
                                <Grid xs={12} direction="row" container>
                                    <Grid xs={11.5}><Typography level="h4">{t('Roof Segement')} #{roofIndex+1}</Typography></Grid>
                                    <Grid xs={0.5}><IconButton variant="soft" aria-label={t('Remove roof segment from this PV Analysis')} onClick={() => removeRoofSegmentFromBuildingSelection(building, roofSegment)}><Delete /></IconButton></Grid>
                                </Grid>

                                <Grid xs={12} direction="row" container>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Module Area')} (m²)</FormLabel>
                                            <Input type="number" placeholder={t('Module Area')} {...formField(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].module_area`)} />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Direction')} (°)</FormLabel>
                                            <Input type="number" placeholder={t('Direction')} {...formField(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].direction`)} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} direction="row" container>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('kWp')}</FormLabel>
                                            <Input type="number" placeholder={t('kWp')} {...formField(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].kwp`)} />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('kWh/kWp')}</FormLabel>
                                            <Input type="number" placeholder={t('kWh/kWp')} {...formField(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].kwh_per_kwp`)} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid xs={12} direction="row" container>
                                    <Grid xs={6}>
                                        <FormControl required>
                                            <FormLabel>{t('Pitch')} (°)</FormLabel>
                                            <Input type="number" placeholder={t('Pitch')} {...formField(`roof_segments[${getRoofIndex(buildingIndex, roofIndex)}].pitch`)} />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={6}>
                                        <FormControl>
                                            <FormLabel>{t('Panels Count')}</FormLabel>
                                            {roofSegment.roofSegmentSolarPotential.panelsCount}
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid xs={12}>&nbsp;</Grid>
                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        selectedBuildings: state.pvAnalysis.selectedBuildings,
        isLoading: state.pvAnalysis.isLoading,
    };
};

const mapDispatchToProps = function (dispatch: Dispatch) {
    return {
        removeBuildingFromSelection: (selectedBuilding: Building) => dispatch(removeBuildingFromSelection(selectedBuilding)),
        removeRoofSegmentFromBuildingSelection: (selectedBuilding: Building, roofSegment: RoofSegment) => dispatch(removeRoofSegmentFromBuildingSelection(selectedBuilding, roofSegment)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedBuildings);
