import { Building } from "features/global-tagger/global-tagger.dto";
import { GeoLocation, Layers, RealEstate, RoofSegment } from "./pv-analysis.dto";
import { TYPES } from "./pv-analysis.actions";

export interface State {
    location: GeoLocation;
    errorMessage: string;
    selectedBuildings: Building[];
    realEstate: RealEstate;
    highlightedBuilding: string;
    isLoading: boolean;
    showRoofSegments: boolean;
    showOnlySelectedBuildings: boolean;
}

const initialState: State = {
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    errorMessage: '',
    selectedBuildings: [],
    realEstate: {
        count: 0,
        buildings: [],
        locationSearch: {
            lat: 51.2108431,
            lng: 6.802931999999999,
            radius: 1000,
            layers: [Layers.SOLAR_POTENTIAL],
            settings: {
                maxSystemCapacityAdjustment: 80,
            },
        },
    },
    highlightedBuilding: '',
    isLoading: false,
    showOnlySelectedBuildings: false,
    showRoofSegments: false,
};

export const pvAnalysis = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case TYPES.SET_LOCATION:
            return Object.assign({}, state, { location: action.location });

        case TYPES.LOAD_REAL_ESTATE_DATA_REQUESTED:
            return Object.assign({}, state, { isLoading: true });
        case TYPES.LOAD_REAL_ESTATE_DATA_SUCCEEDED:
            return Object.assign({}, state, {
                isLoading: false,
                realEstate: action.realEstate,
                selectedBuildings: [],
            });
        case TYPES.LOAD_REAL_ESTATE_DATA_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });

        case TYPES.SET_HIGHLIGHTED_BUILDING:
            return Object.assign({}, state, {
                highlightedBuilding: action.highlightedBuilding,
            });

        case TYPES.ADD_BUILDING_TO_SELECTION:
            console.debug("Adding to selection: " + action.selectedBuilding);
            if (!state.selectedBuildings.some((b: Building) => b.code === action.selectedBuilding.code)) {
                return {
                    ...state,
                    selectedBuildings: [...state.selectedBuildings, action.selectedBuilding]
                };
            }
            return state;

        case TYPES.REMOVE_BUILDING_FROM_SELECTION:
            const updatedSelection = state.selectedBuildings.filter((building: Building) => building.code !== action.selectedBuilding.code);
            return {
                ...state,
                selectedBuildings: updatedSelection
            };

        case TYPES.REMOVE_ROOF_SEGMENT_FROM_BUILDING_SELECTION:
            const updatedBuilding = action.selectedBuilding.roof.roofSegments.filter((roofSegment: RoofSegment) => roofSegment.code !== action.roofSegment.code);
            return {
                ...state,
                selectedBuildings: [
                    ...state.selectedBuildings.filter((building: Building) => building.code !== action.selectedBuilding.code),
                    {
                        ...action.selectedBuilding,
                        roof: {
                            ...action.selectedBuilding.roof,
                            roofSegments: updatedBuilding,
                        },
                    },
                ],
            };

        case TYPES.RESET_BUIDING_SELECTION:
            return Object.assign({}, state, { selectedBuildings: [] });

        case TYPES.TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS:
            console.log("TOGGLE_SHOW_ONLY_SELECTED_BUILDINGS: " + state.showOnlySelectedBuildings);
            return Object.assign({}, state, { showOnlySelectedBuildings: !state.showOnlySelectedBuildings });

        case TYPES.SET_MAX_INSTALATION_CAPACITY:
    return {
        ...state,
        realEstate: {
            ...state.realEstate,
            locationSearch: {
                ...state.realEstate.locationSearch,
                settings: {
                    ...state.realEstate.locationSearch.settings,
                    maxSystemCapacityAdjustment: action.maxInstalationCapacity
                }
            }
        }
    };

        default:
            return state;
    }
};
