import React from 'react';

import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export default function SalesChart({ variant }: any) {
    const chartNumbers: number[] = [];
    for (let i = 0; i < 100; i++) {
        if (variant[i]) {
            chartNumbers[i] = variant[i];
        }
    }

    const data = {
        labels: chartNumbers.map((n, i) => i),
        datasets: [
            {
                label: `${variant.name} ${variant.type}`,
                data: chartNumbers,
                backgroundColor: chartNumbers.map((n) => (n >= 0 ? 'rgba(100, 150, 90, 0.7)' : 'rgba(200, 0, 0, 0.7)')),
                borderColor: chartNumbers.map((n) => (n >= 0 ? 'rgb(80, 120, 70)' : 'rgb(200, 0, 0)')),
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Jahr',
                    font: {
                        size: 16,
                    },
                },
                ticks: {
                    maxRotation: 0,
                    stepSize: 2,
                    beginAtZero: true,
                    font: {
                        size: 16,
                    },
                },
            },

            y: {
                grid: {
                    color: (context: any) =>
                        context.tick && context.tick.value === 0 ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    callback: function (value: any /*, index: any, values: any */) {
                        if (Math.abs(value) >= 10 ** 6) {
                            return value / 10 ** 6 + ' Mio €';
                        } else if (Math.abs(value) >= 10 ** 3) {
                            return value / 10 ** 3 + ' T €';
                        } else if (value === 0) {
                            return 0;
                        }

                        return value + ' €';
                    },
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
}
