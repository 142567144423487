import React, { Fragment, MouseEvent, MutableRefObject, useEffect, useState } from 'react';
import { Photo } from '@mui/icons-material';
import { Button, CircularProgress, Modal, ModalClose, Sheet, Tooltip, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import useDOMRendererScreenshot from '../../../infrastructure/image/useDOMRendererScreenshot';
import { setErrorMessage, toggleShowOnlySelectedBuildings } from '../pv-analysis.actions';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';

interface Properties {
    mapRef: MutableRefObject<any>;
    disabled: boolean;
    takeScreenshot: (blob: Blob) => void;
}

export default function Screenshot(props: Properties) {
    const { mapRef, disabled, takeScreenshot } = props;

    const { selectedBuildings } = useAppSelector((state) => state.pvAnalysis);
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [image, setImage] = useState<string>('');

    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);

    const screenshot = useDOMRendererScreenshot();

    const { t } = useTranslation();

    useEffect(() => {
        if (image) {
            setIsPreviewModalOpen(true);
        }
    }, [image]);

    async function savePhoto(event: MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        dispatch(setErrorMessage(''));
        setIsLoading(true);
        setImage('');

        try {
            if (mapRef) {
                dispatch(toggleShowOnlySelectedBuildings());
                const position = mapRef.current.getBoundingClientRect();
                console.log('position', position);
                // Give the map time to update before capturing the screenshot
                setTimeout(async () => {
                                const canvas = await screenshot.screenshot(mapRef.current, {
                                    top: 100,
                                    right: 100,
                                    useCORS: true,
                                });
                                setImage(canvas.toDataURL('image/png'));
                                canvas.toBlob(async (blob: Blob | null) => {
                                    try {
                                        if (!blob) {
                                            console.error('Could not create a blob from the canvas');
                                            return;
                                        }
                                        takeScreenshot(blob);
                                    } catch (e) {
                                        console.error('Screenshot', 'Could not upload the screenshot', e);
                                        dispatch(setErrorMessage(`Das Bild konnte nicht gespeichert werden: ${e}`));
                                    }
                                    setIsLoading(false);
                                }, 'image/png');
                                dispatch(toggleShowOnlySelectedBuildings());
                            }
                , 100)

            }
        } catch (e) {
            dispatch(toggleShowOnlySelectedBuildings());
            console.warn('Screenshot', 'Could not save the screenshot', e);
            dispatch(setErrorMessage(`Das Bild konnte nicht gespeichert werden: ${e}`));
            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Tooltip
                    describeChild
                    title={t('You must select a tag, before making a screenshot')}
                    disableHoverListener={selectedBuildings.length !== 0}
                >
                    <span>
                        <Button
                            onClick={savePhoto}
                            startDecorator={<Photo />}
                            className="mapScreenshot"
                            disabled={disabled || selectedBuildings.length === 0}
                        >
                            {t('SAVE MAP IMAGE')}
                        </Button>
                    </span>
                </Tooltip>
            )}

            <Modal
                aria-labelledby="preview-modal"
                aria-describedby={`preview-modal-description`}
                open={isPreviewModalOpen}
                onClose={() => setIsPreviewModalOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet variant="outlined" sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}>
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
                        {t('Image Preview')}
                    </Typography>

                    <img src={image} alt={t('Image Preview')} width={400} />
                </Sheet>
            </Modal>
        </Fragment>
    );
}
