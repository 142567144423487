import React, { useEffect, useState } from 'react';
import { Box, FormControl, FormLabel, Typography, Input, Select, Option, Switch } from '@mui/joy';
import { formCheckbox, formFieldBuilder, handleSelect } from '../../common/formikUtils';
import { ROOF_CLADDINGS, ROOF_TYPES } from './selectOptions';
import { connect } from 'react-redux';
import { OnsiteDataPoint } from 'features/onsite/onsite.dto';
import ImageSelector from './components/ImageSelector';
import { ifNotNull } from 'common/helpers';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    formik: any;
}

function FragmentDach(props: Properties) {
    const { formData, formik } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    const [roofCoverSizeVerticalPhoto, setRoofCoverSizeVerticalPhoto] = useState<string[]>([]);
    const [roofCoverSizeHorizontalPhoto, setRoofCoverSizeHorizontalPhoto] = useState<string[]>([]);
    const [roofTruss, setRoofTruss] = useState<string[]>([]);
    const [rafterSpacingPhoto, setRafterSpacingPhoto] = useState<string[]>([]);
    const [rafterWidthPhoto, setRafterWidthPhoto] = useState<string[]>([]);

    useEffect(() => {
        ifNotNull(formData['roof-type'], (value) => formik.setFieldValue('DACHTYP', value['response']));
        ifNotNull(formData['roof-covering'], (value) => formik.setFieldValue('DACHHAUT', value['response']));
        ifNotNull(formData['roof-cover-size-vertical'], (value) => formik.setFieldValue('DECKMASS_VERTIKAL', Number(value['response'])));
        ifNotNull(formData['roof-cover-size-horizontal'], (value) => formik.setFieldValue('DECKMASS_HORIZONTAL', Number(value['response'])));
        ifNotNull(formData['rafter-spacing'], (value) => formik.setFieldValue('SPARRENABSTAND', Number(value['response'])));
        ifNotNull(formData['rafter-width'], (value) => formik.setFieldValue('SPARRENBREITE', Number(value['response'])));
        ifNotNull(formData['rafter-insulation'], (value) => formik.setFieldValue('AUFSPARRENDAEMMUNG', value['response'] == 'Ja'));

        ifNotNull(formData['roof-cover-size-vertical-photo'], (value) => {
            setRoofCoverSizeVerticalPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['roof-cover-size-horizontal-photo'], (value) => {
            setRoofCoverSizeHorizontalPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['roof-truss'], (value) => {
            setRoofTruss(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['rafter-spacing-photo'], (value) => {
            setRafterSpacingPhoto(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['rafter-width-photo'], (value) => {
            setRafterWidthPhoto(value['photos'].map((photo: any) => photo.url));
        });
    }, [formData]);

    return (
        <>
            <Typography level="h3">Dach</Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
                <FormControl required>
                    <FormLabel>Dachtyp</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.DACHTYP}
                        onChange={handleSelect(formik, 'DACHTYP')}
                    >
                        {ROOF_TYPES.map((roof_type) => (
                            <Option key={roof_type} value={roof_type}>
                                {roof_type}
                            </Option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl required>
                    <FormLabel>Dacheindeckung</FormLabel>
                    <Select
                        sx={{ width: '20rem' }}
                        value={formik.values.DACHHAUT}
                        onChange={handleSelect(formik, 'DACHHAUT')}
                    >
                        {ROOF_CLADDINGS.map((roof_cladding) => (
                            <Option key={roof_cladding} value={roof_cladding}>
                                {roof_cladding}
                            </Option>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <FormControl>
                <FormLabel>Deckmaß vertikal (in cm)</FormLabel>
                <Input type="number" placeholder="Deckmaß vertikal (in cm)" {...formField('DECKMASS_VERTIKAL')} />
            </FormControl>
            <FormControl>
                <FormLabel>Aufnahme Deckmaß vertikal</FormLabel>
                <ImageSelector
                    name="AUFNAHME_DECKMASS_VERTIKAL"
                    formik={formik}
                    imageUrls={roofCoverSizeVerticalPhoto}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Deckmaß horizontal (in cm)</FormLabel>
                <Input type="number" placeholder="Deckmaß horizontal (in cm)" {...formField('DECKMASS_HORIZONTAL')} />
            </FormControl>
            <FormControl>
                <FormLabel>Aufnahme Deckmaß horizontal</FormLabel>
                <ImageSelector
                    name="AUFNAHME_DECKMASS_HORIZONTAL"
                    formik={formik}
                    imageUrls={roofCoverSizeHorizontalPhoto}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Dachstuhl</FormLabel>
                <ImageSelector name="AUFNAHME_DACHSTUHL" formik={formik} imageUrls={roofTruss} multiple={true} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Sparrenabstand</FormLabel>
                <ImageSelector name="AUFNAHME_SPARRENABSTAND" formik={formik} imageUrls={rafterSpacingPhoto} />
            </FormControl>
            <FormControl>
                <FormLabel>Sparrenabstand</FormLabel>
                <Input type="number" placeholder="Sparrenabstand" {...formField('SPARRENABSTAND')} />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Sparrenbreite</FormLabel>
                <ImageSelector name="AUFNAHME_SPARRENBREITE" formik={formik} imageUrls={rafterWidthPhoto} />
            </FormControl>
            <FormControl>
                <FormLabel>Sparrenbreite</FormLabel>
                <Input type="number" placeholder="Sparrenbreite" {...formField('SPARRENBREITE')} />
            </FormControl>

            <Typography
                component="label"
                startDecorator={<Switch sx={{ ml: 1 }} {...formCheckbox(formik, 'AUFSPARRENDAEMMUNG')} />}
            >
                Aufsparrendämmung vorhanden
            </Typography>

            <FormControl required>
                <FormLabel>Aufnahme Gesamt</FormLabel>
                <ImageSelector name="AUFNAHME_DROHNE_GESAMT" formik={formik} required={true} />
            </FormControl>
        </>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(FragmentDach);
