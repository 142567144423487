import { PaginatedSearchDto } from 'infrastructure/admi-client/response.dto';

export interface PVAnalysisSearchDto extends PaginatedSearchDto {}

export interface PVAnalysisDto {
    uuid: string;
    name: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface PVAnalysisVariantDto {
    uuid: string;
    name: string;
    description: string;
    author: string;
    createdAt: Date;
}

export interface GeoLocation {
    address: string;
    lat: number;
    lng: number;
}

import { BBox, MultiPolygon, Polygon, Position } from "geojson";

export interface Location {
    lat: number;
    lng: number;
}

export interface LocationSearch {
    location: Location;
    radius: number;
    layers: Layers[];
    maxSystemCapacityAdjustment: number;
}

export enum Layers {
    SOLAR_POTENTIAL = 'solar-potential',
}

export type RealEstateGeometry = Polygon | MultiPolygon;

export interface BuildingFunction {
    code: string;
    name: string;
}

export interface Building {
    code: string;
    name: string;
    box: BBox;
    geometry: RealEstateGeometry;
    buildingFunction: BuildingFunction;
    roof: Roof;
}

export interface PvSettings {
    maxSystemCapacityAdjustment: number;
}

export interface RealEstate {
    count: number;
    locationSearch: {
        lat: number;
        lng: number;
        radius: number;
        layers: Layers[];
        settings?: PvSettings;
    };
    buildings: Building[];
}

export interface RoofSegmenetSolarPotential {
    panelsCount: number;
    panelCapacityWatts: number;
    yearlyEnergyDcKwh: number;
    moduleSizeAssumption: number;
    kwp: number;
    normalizedKwp: number;
    kwhPerKwp: number;
    dataSourceProcessedAt: Date;
    panelHeightMeters: number;
    panelWidthMeters: number;
    panelLifetimeYears: number;
}

export interface RoofSegment {
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegmentSolarPotential: RoofSegmenetSolarPotential;
    pitchDegrees: number;
    direction360Degrees: number;
    directionAzimuthDegrees: number;
}

export interface Roof {
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegments: RoofSegment[];
}
