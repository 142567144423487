import { connect } from 'react-redux';
import React, { ChangeEvent, useState } from 'react';
import Input from '@mui/joy/Input';
import { Row, UpdateTagRequest } from '../tagging.dto';
import { updateTag } from '../tagging.actions';

interface Properties {
    row: Row;
    updateTag: any;
}

function TagInput(props: Properties) {
    const { row, updateTag } = props;

    const [tag, setTag] = useState<string>(row.data.tag);
    const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');

    function temporaryChangeTag(newTagValue: string) {
        setTag(newTagValue);
        setBackgroundColor(newTagValue !== row.data.tag ? '#FFCCCB' : '#FFFFFF');
    }

    function changeTag() {
        if (tag !== row.data.tag) {
            updateTag({ id: row.data.OBJECTID, tag, type: 'pv' });
            setBackgroundColor('#d7ffcb');
        }
    }

    return (
        <Input
            placeholder="_"
            value={tag}
            onChange={(e: ChangeEvent<HTMLInputElement>) => temporaryChangeTag(e.target.value)}
            style={{ backgroundColor: backgroundColor }}
            onBlur={() => {
                changeTag();
            }}
        />
    );
}

const mapDispatchToProps = function (dispatch: any) {
    return {
        updateTag: (updateTagRequest: UpdateTagRequest) => dispatch(updateTag(updateTagRequest)),
    };
};

export default connect(null, mapDispatchToProps)(TagInput);
