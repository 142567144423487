import React from 'react';
import { Alert, Grid } from '@mui/joy';
import { connect } from 'react-redux';
import Search from './components/Search';
import Map from './components/Map';
import RealEstateDetails from './components/RealEstateDetails';
import './GlobalTaggerPage.css';
import PVAnalysis from './components/PVAnalysis';

interface Properties {
    errorMessage: string;
}

function GlobalTaggerPage(props: Properties) {
    const { errorMessage } = props;

    return (
        <Grid container direction="row" xs={12} spacing={2}>
            <Grid container direction="row" xs={12} spacing={2}>
                <Search />
            </Grid>

            <Grid container direction="row" xs={12} spacing={2}>
                {errorMessage && (
                    <Alert variant="solid" color="danger">
                        {errorMessage}
                    </Alert>
                )}
            </Grid>

            <Grid container direction="column" xs={12} spacing={0.3} className="tagging">
                <Grid xs={6} container className="map">
                    <Map />
                </Grid>

                <Grid xs={5.7} container className="dataContainer">
                    <RealEstateDetails />
                    <PVAnalysis />
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        errorMessage: state.tagging.errorMessage,
    };
};

export default connect(mapStateToProps)(GlobalTaggerPage);
