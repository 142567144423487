import dayjs from 'dayjs';

/** Parses and formats a date string as YYYY-MM-DD that is compatible with input fields */
export function asInputDate(date: string | undefined) {
    if (!date) {
        return '';
    }
    return dayjs(date).format('YYYY-MM-DD');
}

/** Parses and formats a date string as DD.MM.YYYY */
export function asGermanDate(date: string | undefined) {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD.MM.YYYY');
}

/** Converts an optional date to an ISO date string */
export function asIsoDate(date?: string) {
    if (!date) {
        return undefined;
    }
    return new Date(date).toISOString();
}
