import * as React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Divider, GlobalStyles, List, ListItem, ListItemContent, Typography, Sheet, Switch } from '@mui/joy';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { getRoutesFlat } from 'app/AppRoutes';
import { PAGETYPE } from 'routesConfig';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import { setUseQAPVCalculator } from 'features/feature-flags/feature-flags.actions';

import i18n from 'infrastructure/i18n/i18next.client';
import '/node_modules/flag-icons/css/flag-icons.min.css';

export default function Sidebar() {
    const location = useLocation();
    
    const { useQAPVCalculator } = useAppSelector((state) => state.featureFlags);
    const dispatch = useAppDispatch();

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                // onClick={() => {}} // closeSidebar()
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <img
                    src="/img/logo_with_text.svg"
                    alt="Logo"
                    style={{ width: '70%' }}
                />
            </Box>

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        flexGrow: 0,
                    }}
                >
                    {getSideBarListItems(
                        getRoutesFlat().filter((page: any) => page.handle?.type === PAGETYPE.MAIN),
                        location,
                    )}
                </List>

                <Divider />

                <Box sx={{ }}>
                    <h4>Feature Switches</h4>

                    <Switch
                        checked={useQAPVCalculator}
                        onChange={(e) => dispatch(setUseQAPVCalculator(e.target.checked))}
                        endDecorator="Test PV Calculator"
                        sx={{alignSelf: "start"}}
                    />
                </Box>

                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        mt: 'auto',
                        flexGrow: 0,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        mb: 2,
                    }}
                >
                    {getSideBarListItems(
                        getRoutesFlat().filter((page: any) => page.handle?.type === PAGETYPE.FOOTER),
                        location,
                    )}
                </List>
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <div>
                    <a href="#" onClick={() => i18n.changeLanguage('de')}>
                        <span className="fi fi-de"></span>
                    </a>
                    &nbsp;
                    <a href="#" onClick={() => i18n.changeLanguage('en')}>
                        <span className="fi fi-us"></span>
                    </a>
                </div>
            </Box>
            {/* <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="sm"
                    src="https://mathiasnitzsche.de/me/2014-06.jpg"
                />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">MadMaxMatze</Typography>
                    <Typography level="body-xs">mathias.nit...@admi.de</Typography>
                </Box>
                <IconButton size="sm" variant="plain" color="neutral">
                    <LogoutRoundedIcon />
                </IconButton>
            </Box> */}
        </Sheet>
    );
}

function getSideBarListItems(pages: Array<any>, location: any) {
    return pages.filter((page) => !page.index && page.handle).map((page) => getSideBarListItem(page, location));
}

function getSideBarListItem(page: any, location: any) {
    const { t } = useTranslation();

    const cleanPagePath = (page.path || '').replace(/^\/+|\/+$/g, '');
    const cleanLocationPath = location.pathname.replace(/^\/+|\/+$/g, '');

    const isNested: boolean = page?.children?.length > 0 && page.id !== 'homepage';

    // TODO implement properly - many ideas how this can fail
    const isSelected = Boolean(
        cleanPagePath === cleanLocationPath ||
            (cleanLocationPath && cleanPagePath && cleanLocationPath.endsWith(cleanPagePath)),
    );
    const isChildSelected = Boolean(cleanLocationPath && cleanPagePath && cleanLocationPath.startsWith(cleanPagePath));

    return (
        <ListItem key={page.id + page.path} nested={isNested}>
            {isNested ? (
                <Toggler
                    defaultExpanded={isChildSelected}
                    renderToggle={({ open, setOpen }) => (
                        <ListItemButton onClick={() => setOpen(!open)}>
                            {page.handle.icon ? <>{page.handle.icon}</> : <AssignmentRoundedIcon />}
                            <ListItemContent>
                                <Typography level="title-sm">{t(page.handle.title)}</Typography>
                            </ListItemContent>
                            <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                        </ListItemButton>
                    )}
                >
                    <List sx={{ gap: 0.5 }}>{getSideBarListItems(page.children, location)}</List>
                </Toggler>
            ) : (
                <ListItemButton
                    selected={isSelected}
                    role="menuitem"
                    component={Link}
                    to={page.path.replace(/:.*/g, '')}
                >
                    {page.handle.icon ? (
                        <>
                            {page.handle.icon}
                            <ListItemContent>
                                <Typography level="title-sm">{t(page.handle.title)}</Typography>
                            </ListItemContent>
                        </>
                    ) : (
                        t(page.handle.title)
                    )}
                </ListItemButton>
            )}
        </ListItem>
    );
}

function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
}: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {
    const [open, setOpen] = React.useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}
