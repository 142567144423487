export interface Location {
    address: string;
    lat: number;
    lng: number;
}

export enum Filter {
    ALL = 'all',
    ONLY_PUBLIC = 'only_public',
}

export interface DataSearch {
    filter: string;
    location: Location;
}

export interface Row {
    data: any;
    color: string;
}

export interface RowConfig {
    isHighlighted: boolean;
}

export interface Geo {
    lat: number;
    lng: number;
}

export interface UpdateTagRequest {
    id: string;
    tag: string;
    type: 'pv';
}
