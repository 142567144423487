import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    FormControl,
    FormLabel,
    Input,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Grid,
    Skeleton,
    CircularProgress,
} from '@mui/joy';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PreviewIcon from '@mui/icons-material/Preview';
import HandymanIcon from '@mui/icons-material/Handyman';

import { formFieldBuilder } from 'common/formikUtils';
import { formValidationSchema, INITIAL_FORM_VALUES } from './formDefinitions';
import PVInput, { DEFAULT_PV_PARAMS, OptimizerMode, PVInputParams } from 'features/pv-calculator/components/PVInput';
import { LoadProfileType, Optimizer, PVResultDto, RoofSegmentDto } from 'features/pv-calculator/pv-calculator.dto';
import { admiPVCalculatorService } from 'features/pv-calculator/admi-pv-calculator.service';
import SalesResult, { SALES_RESULT_WIDTH, SALES_RESULT_HEIGHT } from 'features/sales/SalesResult';
import SalesPrint from 'features/sales/SalesPrint';
import EnergyConsumptionComponents from 'features/sales/components/EnergyConsumptionComponents';
import Search from './components/Search';
import Map, { TakeScreenshotOptions } from './components/Map';
import './PvAnalysisCreatePage.css';
import { admiPVAnalysisService } from './admi-pv-analysis.service';
import { Business, MapOutlined, Tune } from '@mui/icons-material';
import SelectedBuildings from './components/SelectedBuildings';
import PvSizeSlider from './components/PvSizeSlider';
import useDebouncer from 'common/useDebouncer';

interface CalculatorResults {
    optimizedResult: PVResultDto;
    fullRoofResult: PVResultDto;
    optimizer: Optimizer;
    fundingRate: number;
    baseUsageEstimated: boolean;
}

const PV_CALCULATOR_PREVIEW_DEBOUNCE_TIME = 5000;

function PvAnalysisCreatePage() {
    const { t } = useTranslation();

    // TODO: Set roof segments
    const [roofSegments, setRoofSegments] = useState<RoofSegmentDto[]>([]);
    const [screenshotOptions, setScreenshotOptions] = useState<TakeScreenshotOptions | null>(null);
    const [screenshotDataUrl, setScreenshotDataUrl] = useState<string | null>(null);
    const [pvParams, setPvParams] = useState<PVInputParams>(DEFAULT_PV_PARAMS);

    const [error, setError] = useState<string | null>(null);
    const [calculatorResults, setCalculatorResults] = useState<CalculatorResults | null>(null);
    const [calculatorResultsDirty, setCalculatorResultsDirty] = useState<boolean>(false);

    const [isCalculating, setIsCalculating] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        validationSchema: formValidationSchema,
        onSubmit: createPvAnalysis,
    });

    const formField = (name: string) => formFieldBuilder(formik, name);

    const debouncer = useDebouncer();

    // Update PV calculator results when the PV parameters or roof segments change
    useEffect(() => {
        if (pvParams && roofSegments.length > 0) {
            setIsCalculating(true);
            debouncer(calculatePv, PV_CALCULATOR_PREVIEW_DEBOUNCE_TIME);
        }
    }, [pvParams, roofSegments]);

    useEffect(() => {
        if (screenshotDataUrl) {
            URL.revokeObjectURL(screenshotDataUrl);
        }
        if (screenshotOptions) {
            setScreenshotDataUrl(URL.createObjectURL(screenshotOptions.blob));
        }
    }, [screenshotOptions])

    useEffect(() => {
        if (formik.values.roof_segments) {
            setRoofSegments(formik.values.roof_segments.filter((segment) => segment && segment.building_id !== '').map((segment: any) => {
                return {
                    building_id: segment.building_id,
                    modarea: Number(segment.module_area),
                    direction: Number(segment.direction),
                    kwp: Number(segment.kwp),
                    kwh_kwp: Number(segment.kwh_per_kwp),
                    roof_pitch: Number(segment.pitch),
                    center: segment.center,
                };
            }));
        }
    }, [formik.values.roof_segments]);

    async function calculatePv() {
        Promise.all([
            admiPVCalculatorService.doPVOptimization(
                roofSegments,
                pvParams.optimizer,
                pvParams.buildingType,
                pvParams.loadProfiles,
                pvParams.chpProductionProfile,
                pvParams.fundingRate / 100,
                pvParams.constrainFullRoofTo100KWp,
                pvParams.productionOptimizerMode === OptimizerMode.Manual ? pvParams.targetProduction : undefined,
                pvParams.batteryOptimizerMode === OptimizerMode.Manual ? pvParams.batteryKwpFactor : undefined,
            ),
            admiPVCalculatorService.doPVOptimization(
                roofSegments,
                Optimizer.FULL_ROOF,
                pvParams.buildingType,
                pvParams.loadProfiles,
                pvParams.chpProductionProfile,
                0,
                pvParams.constrainFullRoofTo100KWp,
                undefined,
                OptimizerMode.Auto,
            ),
        ])
        .finally(() => {
            setIsCalculating(false);
            setCalculatorResultsDirty(false);
        })
        .then(
            ([optimizedResult, fullRoofResult]) => {
                setCalculatorResults({
                    optimizedResult,
                    fullRoofResult,
                    // Store these also so they don't change when the user changes the input
                    optimizer: pvParams.optimizer,
                    fundingRate: pvParams.fundingRate,
                    baseUsageEstimated: typeof pvParams.loadProfiles.find(p => p.type === LoadProfileType.BASE)?.annual_usage !== 'number',
                });
                setError(null);
            },
            (e: any) => {
                setError(t(admiPVCalculatorService.errorResponseToDescription(e)));
                setCalculatorResults(null);
            },
        );
    }

    async function createPvAnalysis(values: typeof INITIAL_FORM_VALUES) {
        console.debug('createPvAnalysis', values);

        try {
            // await admiPVAnalysisService.createPVAnalysis(values);
            // history.push('/pv-analysis');

            if (!screenshotOptions) {
                setError(t('Please take a screenshot of the map first.'));
                return;
            }

            await admiPVAnalysisService.createPVAnalysisVariant(
                values.name,
                values.description,
                screenshotOptions.mapCenter,
                screenshotOptions.mapZoom,
                screenshotOptions.blob,
            );
        } catch (error) {
            console.error(error);
            console.debug((error as any).message);
            if (error instanceof AxiosError) {
                if (error.message[0]) {
                    setError(error.message[0]);
                }
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="row" xs={12} spacing={5}>
                <Grid xs={12}>
                    <Typography level="h2">{t('New PV Analysis')}</Typography>
                </Grid>

                <Grid xs={12}>
                    <AccordionGroup size="md">
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <HandymanIcon /> {t('General Information')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl required>
                                    <FormLabel>{t('Name')}</FormLabel>
                                    <Input placeholder={t('PV Analysis Name')} {...formField('name')} />
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <MapOutlined /> {t('Search')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <Search />
                                </Grid>
                                <Grid xs={12}>
                                    <Map takeScreenshot={setScreenshotOptions} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <Tune /> {t('Max PV System instalation capacity')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <PvSizeSlider />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <Business /> {t('Buildings')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <SelectedBuildings formik={formik} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <SolarPowerIcon /> {t('PV Calculator')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PVInput
                                    defaultParams={pvParams}
                                    onChange={(value) => {
                                        setCalculatorResultsDirty(true);
                                        setPvParams(value);
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg">
                                    <PreviewIcon /> {t('Preview')}
                                </Typography>
                            </AccordionSummary>

                            {isCalculating ? (
                                <CircularProgress />
                            ) : (
                                <AccordionDetails>
                                    {calculatorResultsDirty && (
                                        <Skeleton
                                            variant="rectangular"
                                            width={SALES_RESULT_WIDTH}
                                            height={SALES_RESULT_HEIGHT}
                                        />
                                    )}

                                    {!calculatorResultsDirty && (
                                        <>
                                            {calculatorResults?.optimizedResult &&
                                                calculatorResults?.fullRoofResult && (
                                                    <SalesPrint
                                                        projectName={formik.values.name}
                                                        energyConsumption={
                                                            <EnergyConsumptionComponents
                                                                components={
                                                                    calculatorResults.optimizedResult.load_summary
                                                                }
                                                                baseUsageEstimated={calculatorResults.baseUsageEstimated}
                                                            />
                                                        }
                                                        plot={
                                                            <>
                                                                {screenshotDataUrl && (
                                                                    <img
                                                                        src={screenshotDataUrl}
                                                                        style={{ width: 378 }}
                                                                    />
                                                                )}
                                                                {!screenshotDataUrl && (
                                                                    <Typography level="body-sm">
                                                                        {t(
                                                                            'Please take a screenshot of the map first.',
                                                                        )}
                                                                    </Typography>
                                                                )}
                                                            </>
                                                        }
                                                    >
                                                        <SalesResult
                                                            result={calculatorResults.optimizedResult}
                                                            fundingRate={calculatorResults.fundingRate}
                                                            optimizer={calculatorResults.optimizer}
                                                        />
                                                        <SalesResult
                                                            result={calculatorResults.fullRoofResult}
                                                            fundingRate={0}
                                                            optimizer={Optimizer.FULL_ROOF}
                                                        />
                                                    </SalesPrint>
                                                )}
                                        </>
                                    )}

                                    {!calculatorResults && (
                                        <Typography>{t('Please fill the PV calculator form first.')}</Typography>
                                    )}
                                </AccordionDetails>
                            )}
                        </Accordion>
                    </AccordionGroup>
                </Grid>

                <Grid xs={12}>
                    <FormControl required sx={{ mb: 2 }}>
                        <FormLabel>{t('Message')}</FormLabel>
                        <Input placeholder={t('Message commenting the changes')} {...formField('description')} />
                    </FormControl>

                    <Button type="submit" loading={formik.isSubmitting}>
                        {t('Create New PV Analysis')}
                    </Button>
                </Grid>

                {error && (
                    <Grid xs={12}>
                        <Alert color="danger">{error}</Alert>
                    </Grid>
                )}
            </Grid>
        </form>
    );
}

export default PvAnalysisCreatePage;
