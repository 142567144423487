/**
 * Resizes the image to the new width while maintaining the aspect ratio.
 * @param dataUrl Image data url to resize
 * @param newWidth New width (while maintaining the aspect ratio)
 * @returns Rotated image data url
 */
export function resizeImage(dataUrl: string, newWidth: number): Promise<string> {
    return new Promise<string>((resolve) => {
        const image = new Image();
        // Rotate the image automatically according to the EXIF orientation, if any
        image.style.imageOrientation = 'from-image';

        image.onload = async () => {
            const canvas = document.createElement('canvas');

            // Maintain aspect ratio
            canvas.width = newWidth;
            canvas.height = image.height * (newWidth / image.width);

            const context = canvas.getContext('2d');
            context?.drawImage(image, 0, 0, canvas.width, canvas.height);

            resolve(canvas.toDataURL('image/jpeg', 0.9));
        };

        image.src = dataUrl;
    });
}
