import { Alert, Grid, Slider } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { setMaxInstalationCapacity } from '../pv-analysis.actions';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import useDebouncer from 'common/useDebouncer';
import { useAppDispatch } from 'redux-app-hooks';

function PvSizeSlider() {
    const dispatch = useAppDispatch();

    const [maxSize, setMaxSize] = useState(80);

    const { t } = useTranslation();

    const debouncer = useDebouncer();

    useEffect(() => {
        if (maxSize) {
            debouncer(() => dispatch(setMaxInstalationCapacity(maxSize)), 500);
        }
    }, [maxSize]);

    return (
        <Grid container xs={12}>
            <Grid xs={12}><Alert color="primary" startDecorator={<Info />}>{t('Disclaimer: If you are creating a potential analysis for a balancing group system, a second system or a roof with an existing PV system, it is probably sensible to create your own roof plan (full occupancy) and then use the slider for the roof area. It is best to compare the available roof area with a measurement of the area in TIM or Google Maps etc')}.</Alert></Grid>
            <Grid xs={12}><Slider value={maxSize} max={100} min={10} valueLabelDisplay="auto" onChange={(event: Event, value: number | number[]) => setMaxSize(value as number)} /></Grid>
        </Grid>
    )
}

export default PvSizeSlider;
