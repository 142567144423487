import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import { CircularProgress } from '@mui/joy';

import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import { loadOnsiteData } from './onsite.actions';
import CreateTenderPage from 'features/tenders/CreateTenderPage';

export default function OnsiteTenderFormPage() {
    const isLoading = useAppSelector((state) => state.onsite.isLoading);
    const dispatch = useAppDispatch();

    const { reference } = useParams();

    useEffect(() => {
        if (reference) {
            dispatch(loadOnsiteData(reference));
        }
    }, [reference]);

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <CreateTenderPage />
            )}
        </Fragment>
    );
}
