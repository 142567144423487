import { Grid } from '@mui/joy';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/joy/Select';
import React, { Fragment, KeyboardEvent, SyntheticEvent, useEffect, useState } from 'react';
import Option from '@mui/joy/Option';
import { Filter, Location } from '../tagging.dto';
import { setFilter, setLocation } from '../tagging.actions';
import { connect } from 'react-redux';
import useGoogleMapsApiLoader from '../../../infrastructure/google/useGoogleMapsApiLoader';
import { admiTaggingService } from '../admi-tagging.service';
import Link from '@mui/joy/Link';
import { useTranslation } from 'react-i18next';

interface Properties {
    location: Location;
    filter: Filter;
    setFilter: any;
    setLocation: any;
}

function Search(props: Properties) {
    const { location, filter, setFilter, setLocation } = props;

    const [address, setAddress] = useState('');
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const loader = useGoogleMapsApiLoader();

    const { t } = useTranslation();

    useEffect(() => {
        console.debug('Search', location, filter);
        loadGeocoder();
    }, []);

    useEffect(() => {
        setAddress(location.address);
    }, [location]);

    async function loadGeocoder() {
        const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
        setGeocoder(new Geocoder());
    }

    async function handleChangeAddress(e: KeyboardEvent<HTMLInputElement>) {
        console.debug('handleChangeAddress', e.key, address, location.address);

        if (e.key === 'Enter' && geocoder !== undefined) {
            const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({ address });
            console.debug(
                'address',
                address,
                location.address,
                geocoderResponse.results[0].geometry.location.lat(),
                geocoderResponse.results[0].geometry.location.lng(),
            );

            setLocation({
                address: address,
                lat: geocoderResponse.results[0].geometry.location.lat(),
                lng: geocoderResponse.results[0].geometry.location.lng(),
            });
        }
    }

    return (
        <Fragment>
            <Grid xs={7}>
                <Input
                    placeholder={t('Search')}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    onKeyDown={handleChangeAddress}
                    startDecorator={<SearchIcon />}
                    sx={{ flexGrow: 1 }}
                />
            </Grid>

            <Grid xs={3}>
                <Select
                    placeholder={'Select the filter…'}
                    value={filter}
                    onChange={(e: SyntheticEvent | null, newValue: string | null) => {
                        setFilter(newValue as string);
                    }}
                >
                    {Object.values(Filter).map((value: string) => (
                        <Option key={value} value={value}>
                            {value.replace('_', ' ').toUpperCase()}
                        </Option>
                    ))}
                </Select>
            </Grid>
            <Grid xs={2} container direction="row" spacing={2} justifyContent="center" alignItems="center">
                <Grid xs={5}>
                    <Link href={admiTaggingService.getPVDataURLJSON(address)} target="_blank">
                        JSON
                    </Link>
                </Grid>
                <Grid xs={5}>
                    <Link href={admiTaggingService.getPVDataURLCSV(address)} target="_blank">
                        CSV
                    </Link>
                </Grid>
            </Grid>
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        location: state.tagging.location,
        filter: state.tagging.filter,
    };
};

const mapDispatchToProps = function (dispatch: any) {
    return {
        setFilter: (filter: Filter) => dispatch(setFilter(filter)),
        setLocation: (location: Location) => dispatch(setLocation(location)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
