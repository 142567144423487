import React, { useEffect, useState } from 'react';
import { Button, Link, Stack, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopIcon from '@mui/icons-material/Stop';

import { useTranslation } from 'react-i18next';

import { VmStateDto, admiPvSolVmService } from './admi-pv-sol-vm.service';

export function PvSolVmPage() {
    const { t } = useTranslation();

    const [vmState, setVmState] = useState<VmStateDto>({
        state: 'loading...',
        last_started_by: '',
    });
    useEffect(() => {
        const load = () => {
            admiPvSolVmService.getVirtualMachineState().then((vmState) => setVmState(vmState));
        };
        load();
        const intervalId = setInterval(load, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);
    const [isLoadingStop, setIsLoadingStop] = useState<boolean>(false);

    return (
        <>
            <Typography level="h3">{t('Virtual Machine: PV*SOL')}</Typography>
            <p>
                {t(
                    'This page allows you to start and stop the virtual machine for PV*SOL. Please make sure that nobody currently uses the virtual machine before stopping it.',
                )}
            </p>

            <Typography level="h4" sx={{ marginTop: 2 }}>
                {t('Manage')}
            </Typography>
            <p>
                <strong>{t('State')}:</strong> {t(vmState.state)}
                <br />
                <strong>
                    {t('Last started by')}: {vmState.last_started_by}
                </strong>{' '}
                {}
            </p>

            <Stack spacing={1} direction="row">
                <Button
                    startDecorator={<NotStartedIcon />}
                    loading={isLoadingStart}
                    loadingPosition="start"
                    onClick={async () => {
                        setIsLoadingStart(true);
                        await admiPvSolVmService.startVirtualMachine();
                        setIsLoadingStart(false);
                    }}
                    disabled={vmState.state == 'running'}
                >
                    {t('Start Virtual Machine')}
                </Button>
                <Button
                    startDecorator={<StopIcon />}
                    loading={isLoadingStop}
                    loadingPosition="start"
                    onClick={async () => {
                        setIsLoadingStop(true);
                        await admiPvSolVmService.stopVirtualMachine();
                        setIsLoadingStop(false);
                    }}
                    disabled={vmState.state == 'stopped'}
                >
                    {t('Stop Virtual Machine')}
                </Button>
            </Stack>

            <Typography level="h4" sx={{ marginTop: 2 }}>
                {t('How to connect')}
            </Typography>
            <Tabs>
                <TabList>
                    <Tab variant="soft" color="primary">
                        Windows
                    </Tab>
                    <Tab variant="soft" color="primary">
                        Mac
                    </Tab>
                </TabList>
                <TabPanel value={0}>
                    <Button
                        startDecorator={<ElectricalServicesIcon />}
                        component="a"
                        href={admiPvSolVmService.buildVmConnectRdpLink()}
                    >
                        {t('Connect')}
                    </Button>
                    <p>{t('Password: Ask an admi colleague')}</p>
                </TabPanel>
                <TabPanel value={1}>
                    <p>
                        Download the RDP client from:{' '}
                        <Link href="https://apps.apple.com/ch/app/microsoft-remote-desktop/id1295203466?mt=12">
                            Mac App Store
                        </Link>
                    </p>
                    <p>
                        Connect using the following credentials:
                        <ul>
                            <li>IP/Host: 20.13.160.190</li>
                            <li>{t('User')}: admi</li>
                            <li>{t('Password: Ask an admi colleague')}</li>
                        </ul>
                    </p>
                </TabPanel>
            </Tabs>
        </>
    );
}
