import React from 'react';
import { formatToGermanNumber } from 'common/toGermanNumber';

const LOAD_COMPONENT_FRIENDLY_NAMES = new Map([
    // These are not translated, because they're part of the sales print
    // which is always in German
    ['base', 'Grundverbrauch'],
    ['custom', 'Zusätzlicher Verbraucher'],
    ['heat_pump', 'Wärmepumpe'],
]);

function getComponentFriendlyName(name: string, baseUsageEstimated: boolean) {
    const friendlyName = LOAD_COMPONENT_FRIENDLY_NAMES.get(name);
    if (!friendlyName) {
        throw new Error(`unknown energy component name: ${name}`);
    }

    const estimated = name !== 'base' || baseUsageEstimated;
    return `${friendlyName} (${estimated ? 'geschätzt' : 'gemessen'})`;
}

export default function EnergyConsuptionComponents({
    components,
    baseUsageEstimated,
}: {
    components: [string, number][];
    baseUsageEstimated: boolean;
}) {
    return (
        <div className="estimatedEnergyUse">
            <strong>Stromverbrauch:</strong>
            <br />
            {components.map(([name, usage]) => (
                <React.Fragment key={name}>
                    <span>{`${getComponentFriendlyName(name, baseUsageEstimated)}: ${formatToGermanNumber(
                        usage,
                        0,
                    )} kWh`}</span>
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
}
