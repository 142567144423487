import { Dispatch } from 'redux';
import { OnsiteDataPoint } from './onsite.dto';
import { admiOnsiteService } from './admi-onsite.service';

export const TYPES = {
    LOAD_ONSITE_DATA_REQUESTED: 'LOAD_ONSITE_DATA_REQUESTED',
    LOAD_ONSITE_DATA_SUCCEEDED: 'LOAD_ONSITE_DATA_SUCCEEDED',
    LOAD_ONSITE_DATA_FAILED: 'LOAD_ONSITE_DATA_FAILED',
};

export const loadOnsiteData = (reference: string) => async (dispatch: Dispatch) => {
    dispatch(loadOnsiteDataRequested(reference));
    try {
        const response = await admiOnsiteService.getOnsite(reference);
        const data: { [reference: string]: OnsiteDataPoint } = {};

        for (const item of response.data) {
            if (!data[item.reference]) {
                data[item.reference] = item;
            }
        }

        dispatch(loadOnsiteDataSucceeded(reference, data));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }

        dispatch(loadOnsiteDataFailed(reference, errorMessage));
    }
};

export function loadOnsiteDataRequested(reference: string) {
    return { type: TYPES.LOAD_ONSITE_DATA_REQUESTED, reference };
}

export function loadOnsiteDataSucceeded(reference: string, data: any) {
    return { type: TYPES.LOAD_ONSITE_DATA_SUCCEEDED, reference, data };
}

export function loadOnsiteDataFailed(reference: string, errorMessage: string) {
    return { type: TYPES.LOAD_ONSITE_DATA_FAILED, reference, errorMessage };
}
