import { API_BASE_URL } from 'appConfig';
import { httpClient } from '../../infrastructure/admi-client/http-client';

export interface VmStateDto {
    state: string;
    last_started_by: string;
}

export const admiPvSolVmService = {
    async startVirtualMachine() {
        const response = await httpClient.post(
            '/api/v1/pv-sol-vm',
            {},
            {
                baseURL: API_BASE_URL,
            },
        );

        return response.data;
    },

    async stopVirtualMachine() {
        const response = await httpClient.delete('/api/v1/pv-sol-vm', {
            baseURL: API_BASE_URL,
        });

        return response.data;
    },

    async getVirtualMachineState() {
        const response = await httpClient.get<VmStateDto>('/api/v1/pv-sol-vm', {
            baseURL: API_BASE_URL,
        });

        return response.data;
    },

    buildVmConnectRdpLink() {
        return `${API_BASE_URL}/data/pv-sol-vm.rdp`;
    },
};
