import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Building, RealEstate } from '../global-tagger.dto';
import {
    Grid,
    Select,
    Option,
    Alert,
    Input,
    CircularProgress,
    Sheet,
    Typography,
    Box,
    FormLabel,
    FormControl,
    Button,
    Modal,
    ModalClose,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
    admiPVCalculatorService,
} from 'features/pv-calculator/admi-pv-calculator.service';
import EnergyConsuptionComponents from 'features/sales/components/EnergyConsumptionComponents';
import SalesChart from 'features/sales/SalesChart';
import SalesResult from 'features/sales/SalesResult';
import SalesPrint from 'features/sales/SalesPrint';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import { pickTextColorBasedOnBackgroundColor } from 'common/pickTextColorBasedOnBackgroundColor';
import { BuildingType, LoadProfileType, Optimizer, PVResultDto, RoofSegmentDto } from 'features/pv-calculator/pv-calculator.dto';
import PlotImage from 'features/real-estate/components/PlotImage';
import { hashStringArray } from 'common/hashUtils';

interface Properties {
    realEstate: RealEstate;
    selectedBuildings: Building[];
    highlightedBuilding: string;
    useQA: boolean;
}

function PVAnalysis(props: Properties) {
    const { realEstate, selectedBuildings, highlightedBuilding, useQA } = props;

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [optimizer, setOptimizer] = useState<Optimizer>(Optimizer.ROI);
    const [buildingType, setBuildingType] = useState<BuildingType>(BuildingType.VERWALTUNGSGEBAEUDE);
    const [fundingRate, setFundingRate] = useState<number>(0);
    const [usage, setUsage] = useState<number | null>(null);
    const [salesPrintTitle, setSalesPrintTitle] = useState<string>(''); // Title input
    const [isSalesPrintOpen, setIsSalesPrintOpen] = useState<boolean>(false);
    const [isSalesPrintTitleModalOpen, setIsSalesPrintTitleModalOpen] = useState<boolean>(false); 

    const [result, setResult] = useState<PVResultDto | null>(null);
    const [fullRoofResult, setFullRoofResult] = useState<PVResultDto | null>(null);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const debounceRef = useRef<NodeJS.Timeout>();

    const handleSalesPrintTitleSubmit = () => {
        setIsSalesPrintTitleModalOpen(false); 
        setIsSalesPrintOpen(true); 
    };


    useEffect(() => {
        console.debug('RealEstateData.realEstate', realEstate);
        if (selectedBuildings.length !== 0 && realEstate !== undefined && realEstate.buildings.length > 0) {
            loadPVPotential();
        }
    }, [realEstate, optimizer, selectedBuildings, buildingType, fundingRate, usage, useQA]);

    // @todo change this method to consume the PV Calculator through our API
    async function loadPVPotential() {
        console.debug('loadPVPotential', realEstate);
        setErrorMessage('');
        setIsLoading(true);
        setResult(null);

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(async () => {
            try {
                const roofSegments: RoofSegmentDto[] = [];


                    for (const building of selectedBuildings) {
                        
                        if (building.roof) {
                            for (const roofSegment of building.roof.roofSegments) {
                                console.debug('loadPVPotential', building.code, roofSegment);
                                // some roof segmenets do not have solar potential
                                if (roofSegment.roofSegmentSolarPotential === undefined) {
                                    continue;
                                }

                                roofSegments.push({
                                    kwp: roofSegment.roofSegmentSolarPotential.normalizedKwp,
                                    kwh_kwp: roofSegment.roofSegmentSolarPotential.kwhPerKwp,
                                    modarea: roofSegment.area,
                                    roof_pitch: roofSegment.pitchDegrees,
                                    direction: roofSegment.direction360Degrees,
                                    building_id: building.code,
                                });
                            }

                            console.debug('loadPVPotential.segment', roofSegments);
                        }
                    }


                const { lat, lng } = realEstate.locationSearch;

                // TODO: If the floor area is passed, the energy consumption will consider that for the estimation
                // This feature is currently disabled.
                // const floorArea = selectedBuildings.length !== 0 ? admiRealEstateService.getBuildingsFloorArea(selectedBuildings) : undefined;

                if (roofSegments.length > 0) {
                    const response = await admiPVCalculatorService.doPVOptimization(
                        roofSegments,
                        optimizer,
                        buildingType,
                        [{ type: LoadProfileType.BASE, annual_usage: usage }],
                        null,
                        fundingRate / 100,
                        false,
                        undefined,
                        undefined,
                        lat,
                        lng,
                        useQA,
                        undefined,
                        // floorArea,
                    );
                    setResult(response);

                    const fullRoofResponse = await admiPVCalculatorService.doPVOptimization(
                        roofSegments,
                        Optimizer.FULL_ROOF,
                        buildingType,
                        [{ type: LoadProfileType.BASE, annual_usage: usage }],
                        null,
                        0,
                        false,
                        undefined,
                        undefined,
                        lat,
                        lng,
                        useQA,
                        undefined,
                        // floorArea,
                    );
                    setFullRoofResult(fullRoofResponse);

                    setIsLoading(false);
                }
            } catch (e: any) {
                console.error('loadPVPotential', e);
                setIsLoading(false);
                setErrorMessage(`There was an error while attempting to process the PV potential: ${e.message}`);
            }
        }, 1000);
    }

    function getBackgroundColor(building: Building): object {
        const bgColor = generateColorHashFromString(`building_${building.code}`);
        const color = pickTextColorBasedOnBackgroundColor(bgColor, '#ffffff', '#000000');

        return {
            backgroundColor: building.code === highlightedBuilding ? `#${bgColor}` : 'transparent',
            color: building.code === highlightedBuilding ? color : '#000000',
        };
    }

    return (
        <Fragment>
            <Grid xs={12} direction="row">
                {errorMessage && (
                    <Alert variant="solid" color="danger">
                        {errorMessage}
                    </Alert>
                )}
            </Grid>

            {selectedBuildings.length !== 0 ? (
                <Grid xs={12} container direction="row" spacing={2}>
                    <Grid xs={12} container direction="row" spacing={1}>
                        <Grid xs={3} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Optimizer')}</FormLabel>
                                <Select
                                    placeholder={t('Optimizer')}
                                    value={optimizer}
                                    onChange={(e, value: any) => setOptimizer(value)}
                                >
                                    {Object.values(Optimizer).map((o: Optimizer) => (
                                        <Option key={o} value={o}>
                                            {t(o)}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={4} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Building type')}</FormLabel>
                                <Select
                                    placeholder={t('Building type')}
                                    value={buildingType}
                                    onChange={(e, value: any) => setBuildingType(value)}
                                >
                                    {Object.values(BuildingType).map((lp: BuildingType) => (
                                        <Option key={lp} value={lp}>
                                            {t(lp)}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={2} direction="column">
                            <FormControl required>
                                <FormLabel>{t('Funding')}</FormLabel>
                                <Input
                                    type="number"
                                    value={fundingRate}
                                    onChange={(e) => setFundingRate(parseFloat(e.target.value))}
                                    endDecorator="%"
                                />
                            </FormControl>
                        </Grid>

                        <Grid xs={3} direction="column">
                            <FormControl>
                                <FormLabel>{t('Usage')}</FormLabel>
                                <Input
                                    type="number"
                                    value={usage ?? ''}
                                    onChange={(e) => setUsage(parseInt(e.target.value))}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid xs={12} container direction="row" justifyContent="right">
                        <Button onClick={() => setIsSalesPrintTitleModalOpen(true)} disabled={isLoading}>
                            {t('Generate Sales Print')}
                        </Button>
                    </Grid>

                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Sheet sx={{ height: '100%', overflow: 'auto', p: 4 }}>
                            <Grid xs={12} container direction="row" spacing={2}>
                                {result && (
                                    <Grid xs={12} container direction="column" spacing={0.5}>
                                        <Grid>
                                            <Typography level="h2">{t('PV Calculation Details')}</Typography>
                                        </Grid>

                                        <Grid>
                                            <strong>Design kWp:</strong> {result.design_kwp.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>Design kWh/a (AC):</strong>{' '}
                                            {result.design_kwh_per_year_ac.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>Initial investment incl. funding:</strong>{' '}
                                            {result.initial_investment_incl_funding.toFixed(2)} €
                                        </Grid>

                                        <Grid>
                                            <strong>Balance after 30 years:</strong>{' '}
                                            {result.balance_30_years.toFixed(2)} €
                                        </Grid>

                                        <Grid>
                                            <strong>Total return per €:</strong> {result.rate_of_return.toFixed(2)}
                                        </Grid>

                                        <Grid>
                                            <strong>CO2 savings:</strong> {result.co2_savings.toFixed(2)} t
                                        </Grid>

                                        <Grid>
                                            <strong>Trees:</strong> {result.trees}
                                        </Grid>

                                        <Grid>
                                            <strong>Profit/loss accumulated:</strong>
                                        </Grid>

                                        <Grid>
                                            {result.profit_loss_accumulated.map((n) => n.toFixed(2)).join(', ')}
                                        </Grid>

                                        <Box sx={{ mt: 5 }}>
                                            <SalesChart variant={result.profit_loss_accumulated} />
                                        </Box>
                                    </Grid>
                                )}

                                <Typography level="h2">{t('Real Estate Details')}</Typography>

                                <Grid xs={12} container direction="column">
                                    <Typography level="h3">{t('Buildings')}</Typography>

                                    {selectedBuildings.map((building: Building) => (
                                        <Grid key={building.code} style={getBackgroundColor(building)}>
                                            <Grid>
                                                <strong>{t('ID')}:</strong> {building.code}
                                            </Grid>
                                            {building.name && (
                                                <Grid>
                                                    <strong>{t('Name')}:</strong> {building.name}
                                                </Grid>
                                            )}
                                            <Grid>
                                                <strong>{t('Function')}:</strong> {building.buildingFunction.name}
                                            </Grid>

                                            {building.roof && (
                                                <Grid xs={12} container direction="column" spacing={1}>
                                                    <Grid>
                                                        <strong>{t('Roof Area')}:</strong> {building.roof?.area} m²
                                                    </Grid>

                                                    <Grid>
                                                        <Typography level="title-sm">{t('Roof Segments')}:</Typography>
                                                    </Grid>
                                                    {building.roof.roofSegments.map((segment, index) => (
                                                        <Grid key={segment.code}>
                                                            <Grid>
                                                                <strong>{t('Area')}:</strong> {segment.area} m²
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Pitch')}:</strong> {segment.pitchDegrees}°
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Direction')}:</strong>{' '}
                                                                {segment.direction360Degrees}°
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Solar Potential')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.normalizedKwp.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWp
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Kwh per Kwp')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.kwhPerKwp.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWp
                                                            </Grid>
                                                            <Grid>
                                                                <strong>{t('Yearly Energy')}:</strong>{' '}
                                                                {segment.roofSegmentSolarPotential?.yearlyEnergyDcKwh.toFixed(
                                                                    2,
                                                                )}{' '}
                                                                kWh
                                                            </Grid>
                                                            {index < building.roof.roofSegments.length - 1 && (
                                                                <Grid>-</Grid>
                                                            )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}

                                            <Grid>---</Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Sheet>
                    )}
                </Grid>
            ) : (
                <Grid xs={12} direction="row">
                    <Alert color="primary" variant="soft">
                        &lt;-- {t('Select buildings to see the PV potential')}
                    </Alert>
                </Grid>
            )}

            <Modal
                open={isSalesPrintTitleModalOpen}
                onClose={() => setIsSalesPrintTitleModalOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10000,
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 600,
                        minWidth: 600,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose onClick={() => setIsSalesPrintTitleModalOpen(false)} />
                    <Typography level="h3" fontWeight="lg" mb={2}>
                        {t('Enter Sales Print Title')}
                    </Typography>
                    <Input
                        placeholder={t('Enter a title for the sales print')}
                        value={salesPrintTitle}
                        onChange={(e) => setSalesPrintTitle(e.target.value)}
                    />
                    <Button onClick={() => handleSalesPrintTitleSubmit()} sx={{ mt: 2 }}>
                        {t('OK')}
                    </Button>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby={t('Sales Print')}
                open={isSalesPrintOpen}
                onClose={() => setIsSalesPrintOpen(false)}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 10000,
                }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 1366,
                        minWidth: 1366,
                        boxShadow: 'lg',
                        borderRadius: 'md',
                        padding: '2rem',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />

                    {selectedBuildings.length !== 0 && result && fullRoofResult && (
                        <SalesPrint
                            projectName={salesPrintTitle}
                            energyConsumption={
                                <EnergyConsuptionComponents
                                    components={result.load_summary}
                                    baseUsageEstimated={!usage}
                                />
                            }
                            plot={
                                <PlotImage uuid={hashStringArray(selectedBuildings.map((building) => building.code))} />
                            }
                        >
                            <SalesResult result={result} fundingRate={fundingRate} optimizer={optimizer} />
                            <SalesResult result={fullRoofResult} fundingRate={0} optimizer={Optimizer.FULL_ROOF} />
                        </SalesPrint>
                    )}
                </Sheet>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        realEstate: state.tagger.realEstate,
        selectedBuildings: state.tagger.selectedBuildings,
        useQA: state.featureFlags.useQAPVCalculator,
        highlightedBuilding: state.tagger.highlightedBuilding,
    };
};

export default connect(mapStateToProps)(PVAnalysis);
