import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Typography, Alert, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { admiPVCalculatorService } from './admi-pv-calculator.service';
import { LoadProfileType, Optimizer, PVResultDto } from './pv-calculator.dto';
import SalesResult from 'features/sales/SalesResult';
import PlotImage from 'features/real-estate/components/PlotImage';
import PVResultInsights from './components/PVResultInsights';
import EnergyConsuptionComponents from 'features/sales/components/EnergyConsumptionComponents';
import PVInput, { DEFAULT_PV_PARAMS, OptimizerMode, PVInputParams } from './components/PVInput';
import SalesPrint from 'features/sales/SalesPrint';
import { useAppSelector } from 'redux-app-hooks';

interface Results {
    optimizedResult: PVResultDto;
    fullRoofResult: PVResultDto;
    projectName: string;
    tagName: string;
    fundingRate: number;
    optimizer: Optimizer;
}

function PVCalculatorErrorBox(props: { error: any }) {
    const { t } = useTranslation();
    return <Alert color="danger">{t(admiPVCalculatorService.errorResponseToDescription(props.error))}</Alert>;
}

export default function PVCalculatorPage() {
    const { t } = useTranslation();
    const { useQAPVCalculator } = useAppSelector(state => state.featureFlags);
    
    const [projectName, setProjectName] = useState<string>('');
    const [tagName, setTagName] = useState<string>('');

    const [pvParams, setPvParams] = useState<PVInputParams>(DEFAULT_PV_PARAMS);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState<Results | null>(null);
    const [resultError, setResultError] = useState<any | null>(null);

    const submit = async (event: any) => {
        event.preventDefault();
        setResultError(null);

        if (pvParams.loadProfiles.length === 0) {
            setResultError(t('Please add at least one load profile.'));
            return;
        }

        setLoading(true);

        const [roofSegments, sum_wb_hu] = await admiPVCalculatorService.getRoofSegments(tagName);
        if (!roofSegments) {
            console.error('No roof segments found for tag', tagName);
            return;
        }

        if (pvParams.fundingRate === null) {
            console.error('Missing required fields');
            return;
        }

        try {
            const [optimizedResult, fullRoofResult] = await Promise.all([
                // Calculate selected config
                admiPVCalculatorService.doPVOptimization(
                    roofSegments,
                    pvParams.optimizer,
                    pvParams.buildingType,
                    pvParams.loadProfiles,
                    pvParams.chpProductionProfile,
                    pvParams.fundingRate / 100,
                    pvParams.constrainFullRoofTo100KWp,
                    pvParams.productionOptimizerMode === OptimizerMode.Manual ? pvParams.targetProduction : undefined,
                    pvParams.batteryOptimizerMode === OptimizerMode.Manual ? pvParams.batteryKwpFactor : undefined,
                    undefined,
                    undefined,
                    useQAPVCalculator,
                    sum_wb_hu,
                    undefined,
                ),

                // Calculate full roof as comparison
                admiPVCalculatorService.doPVOptimization(
                    roofSegments,
                    Optimizer.FULL_ROOF,
                    pvParams.buildingType,
                    pvParams.loadProfiles,
                    pvParams.chpProductionProfile,
                    0,
                    pvParams.constrainFullRoofTo100KWp,
                    undefined,
                    OptimizerMode.Auto,
                    undefined,
                    undefined,
                    useQAPVCalculator,
                    sum_wb_hu,
                    undefined,
                ),
            ]);

            setResults({
                optimizedResult,
                fullRoofResult,
                projectName,
                tagName,
                fundingRate: pvParams.fundingRate,
                optimizer: pvParams.optimizer,
            });
        } catch (e: any) {
            setResultError(e);
        }

        setLoading(false);
    };

    return (
        <form onSubmit={submit}>
            <Stack spacing={1}>
                <Typography level="h3">{t('NRW PV Calculator')}</Typography>

                <FormControl required>
                    <FormLabel>{t('Project name (only used as the sales print title)')}</FormLabel>
                    <Input value={projectName} onChange={(e) => setProjectName(e.target.value)} />
                </FormControl>

                <FormControl required>
                    <FormLabel>{t('Tag name')}</FormLabel>
                    <Input value={tagName} onChange={(e) => setTagName(e.target.value)} />
                </FormControl>

                <PVInput
                    defaultParams={pvParams}
                    result={
                        results
                            ? {
                                  annual_production: results.optimizedResult.design_kwh_per_year_ac,
                                  battery_capacity_factor:
                                      results.optimizedResult.battery_capacity / results.optimizedResult.design_kwp,
                              }
                            : undefined
                    }
                    onChange={setPvParams}
                />

                <Button type="submit" loading={loading}>
                    {t('Calculate')}
                </Button>

                {resultError && <PVCalculatorErrorBox error={resultError} />}

                {results && (
                    <>
                        <SalesPrint
                            projectName={results.projectName}
                            energyConsumption={
                                <EnergyConsuptionComponents
                                    components={results.optimizedResult.load_summary}
                                    baseUsageEstimated={typeof pvParams.loadProfiles.find(p => p.type === LoadProfileType.BASE)?.annual_usage !== 'number'}
                                />
                            }
                            plot={<PlotImage uuid={results.tagName} />}
                        >
                            <SalesResult
                                result={results.optimizedResult}
                                fundingRate={results.fundingRate}
                                optimizer={results.optimizer}
                            />
                            <SalesResult
                                result={results.fullRoofResult}
                                fundingRate={0}
                                optimizer={Optimizer.FULL_ROOF}
                            />
                        </SalesPrint>

                        {results.optimizedResult.roi_insights && (
                            <>
                                <Typography level="h4">{t('Optimization Insights')}</Typography>
                                <PVResultInsights
                                    roiInsights={results.optimizedResult.roi_insights}
                                    bestRateOfReturn={results.optimizedResult.rate_of_return}
                                    bestOwnConsumptionRatio={results.optimizedResult.ratio_own_use}
                                    bestTargetProduction={results.optimizedResult.design_kwh_per_year_ac}
                                />
                            </>
                        )}
                    </>
                )}
            </Stack>
        </form>
    );
}
