import React, { useEffect, useState } from 'react';
import { Alert, Button, FormControl, FormLabel, Input, Option, Select, Stack, Switch, Typography } from '@mui/joy';
import { useFormik } from 'formik';

import { FetchTenderProjectDto, createTenderBoQ, fetchTenderProjects } from './tenderApi';
import { tenderFormValidationSchema } from './tenderFormValidation';
import FragmentElektrischerAnschluss from './FragmentElektrischerAnschluss';
import FragmentKabelfuehrung from './FragmentKabelfuehrung';
import FragmentAllgemein from './FragmentAllgemein';
import FragmentDach from './FragmentDach';
import FragmentDachflaeche from './FragmentDachflaeche';
import { INITIAL_FORM_VALUES } from './initialFormValues';
import { connect } from 'react-redux';
import { AxiosError } from 'axios';

function CreateTenderPage() {
    const [newProject, toggleNewProject] = useState<boolean>(false);
    const [tenderProjects, setTenderProjects] = useState<FetchTenderProjectDto[]>([]);
    useEffect(() => {
        fetchTenderProjects().then((projects) => setTenderProjects(projects));
    }, []);
    const [projectId, setProjectId] = useState<number>();
    const [newProjectName, setNewProjectName] = useState<string>('');

    const [status, setStatus] = useState<boolean | null>(null);

    const [error, setError] = useState<string | null>(null);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        validationSchema: tenderFormValidationSchema,
        onSubmit: async (values) => {
            const projectIdOrName = newProject ? newProjectName : projectId;
            if (typeof projectIdOrName === 'undefined') {
                return;
            }

            try {
                await createTenderBoQ(projectIdOrName, values);
                setStatus(true);
            } catch (error) {
                setStatus(false);
                console.error(error);
                console.debug((error as any).message);
                if (error instanceof AxiosError) {
                    if (error.message[0]) {
                        setError(error.message[0]);
                    }
                }
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                spacing={1.5}
                sx={{
                    display: 'flex',
                    maxWidth: '900px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >
                <Typography level="h1">Leistungsverzeichnis erstellen</Typography>
                <p>
                    Ein Klick auf <i>Erstellen</i> legt ein Leistungsverzeichnis (BoQ) in NOVA AVA an. Das
                    Leistungsverzeichnis kann anschließend in NOVA AVA editiert werden.
                </p>

                <Typography level="h3">NOVA AVA Projekt</Typography>
                <Typography
                    component="label"
                    startDecorator={
                        <Switch sx={{ ml: 1 }} onChange={(event) => toggleNewProject(event.target.checked)} />
                    }
                >
                    Neues Projekt anlegen
                </Typography>

                {!newProject && (
                    <FormControl required>
                        <FormLabel>Bestehendes Projekt auswählen</FormLabel>
                        <Select value={projectId} onChange={(_, value: any) => setProjectId(value)}>
                            {tenderProjects.map((project) => (
                                <Option key={project.id} value={project.id}>
                                    {project.name}
                                </Option>
                            ))}
                        </Select>
                    </FormControl>
                )}
                {newProject && (
                    <FormControl required>
                        <FormLabel>Name des Projekts</FormLabel>
                        <Input
                            placeholder="Name des Projekts"
                            value={newProjectName}
                            onChange={(event) => setNewProjectName(event.target.value)}
                        />
                    </FormControl>
                )}

                <FragmentAllgemein formik={formik} />

                <FragmentDach formik={formik} />

                {Array.from(Array(formik.values.ANZAHL_DACHFLAECHEN).keys()).map((index) => (
                    <FragmentDachflaeche key={index} formik={formik} index={index} />
                ))}

                <FragmentElektrischerAnschluss formik={formik} />

                <FragmentKabelfuehrung formik={formik} />

                <Button type="submit" sx={{ marginBottom: 5 }} loading={formik.isSubmitting}>
                    Erstellen
                </Button>

                {status === true && (
                    <Alert color="success">Die Ausschreibungsunterlagen wurden erfolgreich erstellt.</Alert>
                )}
                {status === false && <Alert color="danger">Fehler beim Erstellen der Ausschreibungsunterlagen ({error})</Alert>}
            </Stack>
        </form>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(CreateTenderPage);
