import * as yup from 'yup';

export const INITIAL_FORM_VALUES = {
    name: '',
    description: '',
    roof_segments: [{
        building_id: '',
        module_area: '',
        direction: '',
        kwp: '',
        kwh_per_kwp: '',
        pitch: '',
        center: [],
    }],
}

export const formValidationSchema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    roof_segment: yup.array(
        yup.object({
            module_area: yup.number().positive().required(),
            direction: yup.number().min(0).required(),
            kwp: yup.number().positive().required(),
            kwh_per_kwp: yup.number().positive().required(),
            pitch: yup.number().min(0).required(),
        }),
    ),
})
