import * as yup from 'yup';

export const tenderFormValidationSchema = yup.object({
    DACHFLAECHE_POTENZIAL: yup.number().positive().required(),
    ANZAHL_DACHFLAECHEN: yup.number().integer().positive().required(),
    PEAK_LEISTUNG: yup.number().positive().required(),
    BATTERIE_KAPAZITAET: yup.number().min(0).required(),

    DECKMASS_VERTIKAL: yup.number().positive(),
    DECKMASS_HORIZONTAL: yup.number().positive(),
    SPARRENABSTAND: yup.number().positive(),
    SPARRENBREITE: yup.number().positive(),

    KABELFUEHRUNG_DC_AUSSEN: yup.number().positive(),
    ANZAHL_WDB_AUSSEN: yup.number().integer().min(0).required(),
    ANZAHL_WDB_INNEN: yup.number().integer().min(0).required(),

    DACHFLAECHE: yup.array(
        yup.object({
            POTENZIAL: yup.number().positive().required(),
            DACHNEIGUNG: yup.number().min(0).required(),
            TRAUFHOEHE: yup.number().positive().required(),
            TRAUFLAENGE: yup.number().min(0).required(),
        }),
    ),
});
