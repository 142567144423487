import { Filter, Geo, Location, Row, RowConfig } from './tagging.dto';
import { TYPES } from './tagging.actions';

export interface State {
    geo: Geo;
    rows: Row[];
    rowConfig: RowConfig[];
    location: Location;
    filter: Filter;
    isLoading: boolean;
    selectedTag: string;
    errorMessage: string;
}

const initialState: State = {
    geo: {
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    rows: [],
    rowConfig: [],
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    filter: Filter.ALL,
    isLoading: false,
    selectedTag: '',
    errorMessage: '',
};

export const tagging = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TYPES.LOAD_PV_DATA_REQUESTED:
            return Object.assign({}, state, { isLoading: true });
        case TYPES.LOAD_PV_DATA_SUCCEEDED:
            return Object.assign({}, state, {
                isLoading: false,
                rows: action.rows,
                rowConfig: action.rowConfig,
            });
        case TYPES.LOAD_PV_DATA_FAILED:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage,
            });

        case TYPES.SET_ROW_CONFIG:
            return Object.assign({}, state, { rowConfig: action.rowConfig });

        case TYPES.SET_FILTER:
            return Object.assign({}, state, { filter: action.filter });

        case TYPES.SET_LOCATION:
            return Object.assign({}, state, { location: action.location });

        case TYPES.CHANGE_ROW_CONFIG_HIGHLIGHT:
            const rowConfig = state.rowConfig.map((config: RowConfig, index: number) => {
                if (index === action.index) {
                    return Object.assign({}, config, {
                        isHighlighted: action.isHighlighted,
                    });
                }
            });

            return Object.assign({}, state, { rowConfig });

        case TYPES.SET_SELECTED_TAG:
            return Object.assign({}, state, { selectedTag: action.selectedTag });

        case TYPES.SET_ERROR_MESSAGE:
            return Object.assign({}, state, { errorMessage: action.errorMessage });

        default:
            return state;
    }
};
