import { area, feature, featureCollection, flip } from '@turf/turf';

import { Building } from 'features/global-tagger/global-tagger.dto';
import { httpClient } from '../../infrastructure/admi-client/http-client';

export const admiRealEstateService = {
    async getPlotImage(uuid: string): Promise<any> {
        uuid = this.formatUuid(uuid);

        const response = await httpClient.get(`/api/v1/real-estate/plot/${uuid}/image`, {
            responseType: 'arraybuffer',
        });

        return response.data;
    },

    async uploadPlotImage(uuid: string, image: Blob): Promise<any> {
        uuid = this.formatUuid(uuid);

        const formData = new FormData();
        formData.append('image', image, `${uuid}.png`);

        const response = await httpClient.post(`/api/v1/real-estate/plot/${uuid}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    },

    formatUuid(uuid: string): string {
        return uuid.replace(/\//g, '-');
    },

    getBuildingsFloorArea(buildings: Building[]): number {
        return area(featureCollection(buildings.map(building => flip(feature(building.geometry)))));
    },
};
