import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/joy';
import { Position } from 'geojson';
import { coordEach, feature, flatten } from '@turf/turf';

import useGoogleMapsApiLoader from 'infrastructure/google/useGoogleMapsApiLoader';
import { generateColorHashFromString } from 'common/generateColorHashFromString';
import { Building, Layers } from '../pv-analysis.dto';
import { addBuildingToSelection, loadRealEstateData, removeBuildingFromSelection, setHighlightedBuilding, setLocation } from '../pv-analysis.actions';
import Screenshot from './Screenshot';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';

export interface TakeScreenshotOptions {
    blob: Blob;
    mapCenter: Position;
    mapZoom: number;
}

interface Properties {
    takeScreenshot: (options: TakeScreenshotOptions) => void;
}

export default function Map(props: Properties) {
    const {
        location,
        realEstate,
        selectedBuildings,
        highlightedBuilding,
        showRoofSegments,
        showOnlySelectedBuildings,
    } = useAppSelector((state) => state.pvAnalysis);
    const dispatch = useAppDispatch();

    const { takeScreenshot } = props;

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});
    const [roofSegmentPolygons, setRoofSegmentPolygons] = useState<{
        [key: string]: google.maps.Polygon;
    }>({});

    const [screenshotSaved, setScreenshotSaved] = useState<boolean>(false);

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);

    useEffect(() => {
        console.debug('Map');
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshPolygons();
        refreshRoofSegments();
        refreshSearchMarker();

        setScreenshotSaved(false);
    }, [location, realEstate, showRoofSegments]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        refreshPolygonsHighlight();
    }, [highlightedBuilding]);

    useEffect(() => {
        refreshPolygons();
    }, [selectedBuildings, showOnlySelectedBuildings]);

    useEffect(() => {
        console.debug('RealEstateData');
    }, []);

    useEffect(() => {
        if (realEstate !== undefined) {
            let ps = realEstate.buildings;
            if (selectedBuildings.length !== 0 && ps.length > 0) {
                ps = ps.filter((building) =>  selectedBuildings.some((b: Building) => b.code === building.code));
            }
        }
    }, [realEstate, selectedBuildings]);

    useEffect(() => {
        console.debug('RealEstateData.location', location);
        dispatch(loadRealEstateData({
            location,
            radius: 200,
            layers: [Layers.SOLAR_POTENTIAL],
            maxSystemCapacityAdjustment: realEstate.locationSearch.settings?.maxSystemCapacityAdjustment || 100,
        }));
    }, [location, realEstate.locationSearch.settings?.maxSystemCapacityAdjustment]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions: google.maps.MapOptions = {
            zoom: 18,
            center: { lat: location.lat, lng: location.lng },
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tilt: 0,
            tiltInteractionEnabled: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');

        if (map !== undefined && realEstate !== undefined) {
            // Clear existing polygons
            Object.keys(polygons).forEach((key) => polygons[key].setMap(null));
            setPolygons({});

            // Define the color for selected buildings
            const selectedColor = 'f5cb5a';

            // Iterate over all buildings
            realEstate.buildings.forEach((building) => {
                if (showOnlySelectedBuildings && !selectedBuildings.some((b) => b.code === building.code)) {
                    return;
                }
                // Determine the color: selected buildings use selectedColor, others get unique colors
                const color = selectedBuildings.some((b: Building) => b.code === building.code)
                            ? selectedColor
                            : generateColorHashFromString(`building_${building.code}`);

                console.debug('building geometry', building.geometry);

                const buildingsPolygons = flatten(feature(building.geometry));
                buildingsPolygons.features.forEach((feature) => {
                    console.debug('feature', feature);

                    const polygonData: { lat: number; lng: number; }[] = [];
                    coordEach(feature, (coord) => {
                        polygonData.push({ lat: coord[0], lng: coord[1] });
                    });

                    // Create a new polygon with the determined color
                    const polygon = new google.maps.Polygon({
                        paths: polygonData,
                        strokeColor: `#${color}`,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: `#${color}`,
                        fillOpacity: highlightedBuilding === building.code ? 1 : 0.5,
                    });

                    // Attach event listeners for hover and click
                    attachPolygonInfoWindow(polygon, building);
                    google.maps.event.addListener(polygon, 'mouseover', () => dispatch(setHighlightedBuilding(building.code)));
                    google.maps.event.addListener(polygon, 'mouseout', () => dispatch(setHighlightedBuilding('')));
                    google.maps.event.addListener(polygon, 'click', () => {
                        console.debug("Clicked " + building.code);
                        if (selectedBuildings.some((b: Building) => b.code === building.code)) {
                            dispatch(removeBuildingFromSelection(building));
                        } else {
                            dispatch(addBuildingToSelection(building));
                        }
                        // Refresh polygons to update selected buildings' colors
                        refreshPolygons();
                    });

                    // Display the polygon on the map
                    polygon.setMap(map);

                    // Update state with the new polygon
                    const statePolygons = polygons;
                    statePolygons[building.code] = polygon;
                    setPolygons(statePolygons);
                });
            });
        }
    }

    async function refreshRoofSegments() {
        console.debug('refreshRoofSegments', showRoofSegments);
        if (map !== undefined) {
            Object.keys(roofSegmentPolygons).forEach((key) => roofSegmentPolygons[key].setMap(null));

            realEstate.buildings.forEach((building: Building) => {
                if (showRoofSegments && building.roof !== undefined) {
                    for (const roofSegment of building.roof.roofSegments) {
                        console.debug('roofSegment', roofSegment);

                        const boundingBox = {
                            north: roofSegment.bbox[0],
                            east: roofSegment.bbox[1],
                            south: roofSegment.bbox[2],
                            west: roofSegment.bbox[3],
                        };

                        const roofSegmentPolygonData = [
                            { lat: boundingBox.north, lng: boundingBox.west },
                            { lat: boundingBox.north, lng: boundingBox.east },
                            { lat: boundingBox.south, lng: boundingBox.east },
                            { lat: boundingBox.south, lng: boundingBox.west },
                            { lat: boundingBox.north, lng: boundingBox.west },
                        ];
                        const roofColor = generateColorHashFromString(`roof_${building.code}`);
                        const roofSegmentPolygon: google.maps.Polygon = new google.maps.Polygon({
                            paths: roofSegmentPolygonData,
                            strokeColor: `#${roofColor}`,
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            fillColor: `#${roofColor}`,
                            fillOpacity: 0.5,
                        });
                        const statePolygons = roofSegmentPolygons;
                        statePolygons[roofSegment.code] = roofSegmentPolygon;
                        setRoofSegmentPolygons(statePolygons);
                        roofSegmentPolygon.setMap(map);
                    }
                }
            });
        }
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const center = map.getCenter();
                if (!center) {
                    throw new Error('Map center is not defined');
                }

                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: center,
                });
                console.debug('geocoderResponse', geocoderResponse);

                dispatch(setLocation({
                    address: geocoderResponse.results[0].formatted_address,
                    lat: center.lat(),
                    lng: center.lng(),
                }));
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    async function refreshCenter(): Promise<void> {
        console.debug('refreshCenter', location);

        if (map !== undefined) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    function refreshPolygonsHighlight(): void {
        console.debug('refreshPolygonsHighlight');

        if (map !== undefined && polygons !== undefined) {
            Object.keys(polygons).forEach((key) => {
                polygons[key].setOptions({
                    fillOpacity: key == highlightedBuilding ? 1 : 0.5,
                });
            });
        }
    }

    function takeScreenshotWithMapProps(blob: Blob) {
        const mapCenter = map?.getCenter();
        const mapZoom = map?.getZoom();
        if (mapCenter?.lng() !== undefined && mapCenter?.lat() !== undefined && mapZoom !== undefined) {
            takeScreenshot({ blob, mapCenter: [mapCenter.lng(), mapCenter.lat()], mapZoom });
            setScreenshotSaved(true);
        }
    }

    return (
        <Grid xs={8} container className="map" justifyContent="center">
            <Grid style={{ height: '5%', width: '100%' }}>
                <Screenshot mapRef={mapRef} disabled={screenshotSaved} takeScreenshot={takeScreenshotWithMapProps} />
            </Grid>
            <Grid style={{ height: '95%', width: '100%' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
            </Grid>
        </Grid>
    );

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, building: Building) {
        const infoWindow = new google.maps.InfoWindow();

        let kwp = 0;
        let kwhPerKwp = 0;
        for (const key in building.roof?.roofSegments) {
            const roofSegment = building.roof?.roofSegments[key];
            kwp += roofSegment.roofSegmentSolarPotential?.kwp;
            kwhPerKwp += roofSegment.roofSegmentSolarPotential?.kwhPerKwp;
        }
        kwhPerKwp = kwhPerKwp / building.roof?.roofSegments.length;

        google.maps.event.addListener(polygon, 'mouseover', (e: any) => {
            console.log(building);
            infoWindow.setContent(
                `<b>Building Function:</b> ${building.buildingFunction.name}<br/>` +
                `<b>Roof Area:</b> ${building.roof?.area?.toFixed(2)} m²<br/>` +
                `<b>Potential:</b> ${kwp.toFixed(2)} kWp<br/>` +
                `<b>Efficiency (avg):</b> ${kwhPerKwp.toFixed(2)} kWh/kWp<br/>`,
            );
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }
}
