import { connect } from 'react-redux';
import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { admiRealEstateService } from '../admi-real-estate.service';
import { CircularProgress } from '@mui/joy';
import { useTranslation } from 'react-i18next';

interface Properties {
    uuid: string;
    overrideImage?: string;
}

function PlotImage(props: Properties) {
    const { uuid, overrideImage } = props;

    const [image, setImage] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const imageRef = useRef<HTMLImageElement>(null) as MutableRefObject<HTMLImageElement>;

    const { t } = useTranslation();

    useEffect(() => {
        resolveImage();
    }, [uuid, overrideImage]);

    useEffect(() => {
        loadImage();
    }, [image]);

    async function resolveImage() {
        let url: string | undefined;
        setIsLoading(true);

        if (overrideImage) {
            try {
                await fetch(overrideImage, { method: 'HEAD' });
                url = overrideImage;
            } catch (e) {
                console.warn('PlotImage', 'Alternative image not found');
            }
        }

        // we only fetch from the cloud in case there is no override image
        //  (which currently is coming from the spreadhseet)
        if (url === undefined) {
            try {
                const imageBlob = await admiRealEstateService.getPlotImage(uuid);

                url = URL.createObjectURL(new Blob([imageBlob]));
            } catch (e) {
                console.warn('PlotImage', 'No image found on the Cloud Storage.');
            }
        }

        setImage(url);
        setIsLoading(false);
    }

    function loadImage() {
        if (!imageRef) {
            console.warn('PlotImage', 'Image reference not loaded yet');
            return;
        }

        if (!image) {
            console.warn('PlotImage', 'No image loaded');
            return;
        }

        imageRef.current.src = image;
    }

    return (
        <Fragment>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <Fragment>
                    {!image ? (
                        <p>{t('No Image (Make a Screenshot or test the Spreadsheet)')}</p>
                    ) : (
                        <img ref={imageRef} alt="Plot" />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default connect()(PlotImage);
