import { httpClient } from '../../infrastructure/admi-client/http-client';
import { UpdateTagRequest } from './tagging.dto';
import { API_BASE_URL } from '../../appConfig';

const ADMI_BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export const admiTaggingService = {
    async loadPVDataForLocation(location: any, filter: string): Promise<any> {
        console.debug('loadPVDataForLocation', location);

        let url = '/api/v1/nrw-info?limit=100&cacheInvalidator=' + new Date().getTime();
        if (filter) {
            url += `&filter=${filter}`;
        }
        if (location.address) {
            url += `&address=${location.address}`;
        } else {
            url += `&lat=${location.lat}&lng=${location.lng}`;
        }

        const response = await httpClient.get(url, {
            baseURL: ADMI_BASE_URL,
        });

        return response.data;
    },

    async updateTag(updateTagRequest: UpdateTagRequest): Promise<any> {
        console.debug('updateTag', updateTagRequest);

        const response = await httpClient.post('/api/v1/nrw-tags/set', updateTagRequest, {
            baseURL: ADMI_BASE_URL,
        });

        return response.data;
    },

    getPVDataURLJSON(address: string): string {
        return `${ADMI_BASE_URL}/api/v1/nrw-info?limit=100&address=${address}`;
    },

    getPVDataURLCSV(address: string): string {
        return `${ADMI_BASE_URL}/api/v1/nrw-pv?format=csv&limit=100&address=${address}`;
    },
};
