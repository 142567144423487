import { TYPES } from './onsite.actions';
import { OnsiteDataPoint } from './onsite.dto';

export interface State {
    formData: { [reference: string]: OnsiteDataPoint };
    isLoading: boolean;
}

const initialState: State = {
    formData: {},
    isLoading: false,
};

export const onsite = (state: State = initialState, action: any): State => {
    switch (action.type) {
        case TYPES.LOAD_ONSITE_DATA_SUCCEEDED:
            return Object.assign({}, state, {
                formData: action.data,
                isLoading: false,
            });

        case TYPES.LOAD_ONSITE_DATA_REQUESTED:
            return Object.assign({}, state, { isLoading: true });

        case TYPES.LOAD_ONSITE_DATA_FAILED:
            return Object.assign({}, state, { isLoading: false });

        default:
            return state;
    }
};
