import { TYPES } from './feature-flags.actions';

export interface State {
    useQAPVCalculator: boolean;
}

const initialState: State = {
    useQAPVCalculator: false,
};

export const featureFlags = (state = initialState, action: any) => {
    switch (action.type) {
        case TYPES.SET_USE_QA_PV_CALCULATOR:
            return Object.assign({}, state, { useQAPVCalculator: action.useQAPVCalculator });
        default:
            return state;
    }
};
