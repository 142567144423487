import React from 'react';

import './TaggingPage.css';

import { Grid } from '@mui/joy';
import Search from './components/Search';
import Map from './components/Map';
import TaggingTable from './components/TaggingTable';
import { connect } from 'react-redux';
import Alert from '@mui/joy/Alert';

interface Properties {
    errorMessage: string;
}

function TaggingPage(props: Properties) {
    const { errorMessage } = props;

    return (
        <Grid container direction="row" xs={12} spacing={2} className="tagging" alignContent="flex-start">
            <Grid container direction="row" xs={12} spacing={2}>
                <Search />
            </Grid>

            <Grid container direction="row" xs={12} spacing={2}>
                {errorMessage && (
                    <Alert variant="solid" color="danger">
                        {errorMessage}
                    </Alert>
                )}
            </Grid>

            <Grid container direction="column" xs={12} spacing={2} className="tagging">
                <Grid xs={6} container className="map">
                    <Map />
                </Grid>

                <Grid xs={6} container className="dataContainer">
                    <TaggingTable />
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        errorMessage: state.tagging.errorMessage,
    };
};

export default connect(mapStateToProps)(TaggingPage);
