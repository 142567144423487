import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Input, Select, Option, Switch, Slider, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { LoadProfileDto, BuildingType, LoadProfileType, Optimizer } from '../pv-calculator.dto';
import LoadProfilesContainer from './LoadProfilesContainer';
import CHPProducer from './CHPProducer';
import { useAppSelector } from 'redux-app-hooks';

export enum OptimizerMode {
    Auto,
    Manual,
}

export interface PVInputParams {
    optimizer: Optimizer;
    buildingType: BuildingType;
    fundingRate: number;
    productionOptimizerMode: OptimizerMode;
    targetProduction: number;
    batteryOptimizerMode: OptimizerMode;
    batteryKwpFactor: number;
    loadProfiles: LoadProfileDto[];
    chpProductionProfile: number[] | null;
    constrainFullRoofTo100KWp: boolean;
}

export const DEFAULT_PV_PARAMS: PVInputParams = {
    optimizer: Optimizer.ROI,
    buildingType: BuildingType.SCHULE,
    fundingRate: 0,
    productionOptimizerMode: OptimizerMode.Auto,
    targetProduction: 0,
    batteryOptimizerMode: OptimizerMode.Auto,
    batteryKwpFactor: 1.0,
    loadProfiles: [{ type: LoadProfileType.BASE, annual_usage: null }],
    chpProductionProfile: null,
    constrainFullRoofTo100KWp: false,
};

export default function PVInput(props: {
    defaultParams?: PVInputParams;
    result?: { annual_production: number; battery_capacity_factor: number };
    onChange: (params: PVInputParams) => void;
}) {
    const { t } = useTranslation();
    const { useQAPVCalculator } = useAppSelector((state) => state.featureFlags);

    const [params, setParams] = useState<PVInputParams>(props.defaultParams ?? DEFAULT_PV_PARAMS);

    // When switching to manual, use the values from the result
    useEffect(() => {
        if (params.productionOptimizerMode === OptimizerMode.Manual && props.result) {
            updateParams({ targetProduction: props.result.annual_production });
        }
    }, [params.productionOptimizerMode]);
    useEffect(() => {
        if (params.batteryOptimizerMode === OptimizerMode.Manual && props.result) {
            updateParams({ batteryKwpFactor: props.result.battery_capacity_factor });
        }
    }, [params.batteryOptimizerMode]);

    const updateParams = (newParams: Partial<PVInputParams>) => {
        const updatedParams = { ...params, ...newParams };
        setParams(updatedParams);
        props.onChange(updatedParams);
    };

    return (
        <Stack spacing={1}>
            <FormControl required>
                <FormLabel>{t('Optimizer')}</FormLabel>
                <Select value={params.optimizer} onChange={(e, value: any) => updateParams({ optimizer: value })}>
                    {Object.values(Optimizer).map((o: Optimizer) => (
                        <Option key={o} value={o}>
                            {t(o)}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>{t('Building type')}</FormLabel>
                <Select value={params.buildingType} onChange={(e, value: any) => updateParams({ buildingType: value })}>
                    {Object.values(BuildingType).map((lp: BuildingType) => (
                        <Option key={lp} value={lp}>
                            {t(lp)}
                        </Option>
                    ))}
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>{t('Funding rate (0 - 100 %)')}</FormLabel>
                <Input
                    type="number"
                    value={params.fundingRate}
                    onChange={(e) => updateParams({ fundingRate: parseFloat(e.target.value) })}
                />
            </FormControl>

            <FormLabel>{t('Load profiles')}</FormLabel>
            <LoadProfilesContainer value={params.loadProfiles} onChange={(value) => updateParams({ loadProfiles: value })} />

            {useQAPVCalculator && <CHPProducer onChange={(value) => updateParams({ chpProductionProfile: value })} />}

            {params.optimizer !== Optimizer.FULL_ROOF && (
                <>
                    <Switch
                        checked={params.productionOptimizerMode === OptimizerMode.Manual}
                        onChange={(e) =>
                            updateParams({
                                productionOptimizerMode: e.target.checked ? OptimizerMode.Manual : OptimizerMode.Auto,
                            })
                        }
                        endDecorator={t('Manually optimize annual target production')}
                        sx={{ alignSelf: 'start' }}
                    />
                    {params.productionOptimizerMode === OptimizerMode.Manual && (
                        <FormControl>
                            <FormLabel>{t('Annual target production (kWh/a)')}</FormLabel>
                            <Input
                                type="number"
                                value={params.targetProduction}
                                onChange={(e) => updateParams({ targetProduction: parseInt(e.target.value) })}
                            />
                        </FormControl>
                    )}
                </>
            )}

            <Switch
                checked={params.batteryOptimizerMode === OptimizerMode.Manual}
                onChange={(e) =>
                    updateParams({ batteryOptimizerMode: e.target.checked ? OptimizerMode.Manual : OptimizerMode.Auto })
                }
                endDecorator={t(
                    "Manually optimize battery capacity (for the funding optimizer, 'auto' still means the fixed factors)",
                )}
                sx={{ alignSelf: 'start' }}
            />
            {params.batteryOptimizerMode === OptimizerMode.Manual && (
                <FormControl>
                    <FormLabel>{t('Battery size factor (only fill if deviating)')}</FormLabel>
                    <Slider
                        step={0.1}
                        min={0}
                        max={2}
                        marks
                        valueLabelDisplay="on"
                        value={params.batteryKwpFactor}
                        onChange={(e, value) => updateParams({ batteryKwpFactor: value as number })}
                    />
                </FormControl>
            )}

            <Switch
                checked={params.constrainFullRoofTo100KWp}
                onChange={(e) => updateParams({ constrainFullRoofTo100KWp: e.target.checked })}
                endDecorator={t('Constrain PV system to 100 kWp')}
                sx={{ alignSelf: 'start' }}
            />
        </Stack>
    );
}
