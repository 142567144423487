import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

import App from 'app/App';
import { Provider } from 'react-redux';
import store from './store';

import * as Sentry from "@sentry/react";

import { SENTRY_DNS } from 'appConfig';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from './infrastructure/i18n/i18next.client'; // this is what makes the translation work. DO NOT REMOVE IT

Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });


const root = document.getElementById('root') as HTMLDivElement;
ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
);

reportWebVitals();
