import { DataSearch, Location, Row, RowConfig, UpdateTagRequest } from './tagging.dto';
import { admiTaggingService } from './admi-tagging.service';
import { Dispatch } from 'redux';
import { generateColorHashFromString } from '../../common/generateColorHashFromString';

export const TYPES = {
    LOAD_PV_DATA_REQUESTED: 'LOAD_PV_DATA_REQUESTED',
    LOAD_PV_DATA_SUCCEEDED: 'LOAD_PV_DATA_SUCCEEDED',
    LOAD_PV_DATA_FAILED: 'LOAD_PV_DATA_FAILED',

    SET_ROW_CONFIG: 'SET_ROW_CONFIG',
    CHANGE_ROW_CONFIG_HIGHLIGHT: 'CHANGE_ROW_CONFIG_HIGHLIGHT',

    SET_FILTER: 'SET_FILTER',

    SET_LOCATION: 'SET_LOCATION',

    SET_SELECTED_TAG: 'SET_SELECTED_TAG',

    UPDATE_TAG_REQUESTED: 'UPDATE_TAG_REQUESTED',
    UPDATE_TAG_FAILED: 'UPDATE_TAG_FAILED',
    UPDATE_TAG_SUCCEEDED: 'UPDATE_TAG_SUCCEEDED',

    SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
};

export const loadPVData = (dataSearch: DataSearch) => async (dispatch: Dispatch) => {
    dispatch(loadPVDataRequested(dataSearch));
    console.debug('loadPVData.dataSearch', dataSearch);

    try {
        const response = await admiTaggingService.loadPVDataForLocation(dataSearch.location, dataSearch.filter);
        const rows: Row[] = [];
        const rowConfig: RowConfig[] = [];
        if (response.pv) {
            response.pv.forEach((row: any, index: number) => {
                rows[index] = {
                    data: row,
                    color: generateColorHashFromString(row.geb_id + row.Fest_ID),
                };
                rowConfig[index] = {
                    isHighlighted: false,
                };
            });
        }

        dispatch(loadPVDataSucceeded(dataSearch, rows, rowConfig));
    } catch (e) {
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        console.error('loadPVData', e, errorMessage);

        dispatch(loadPVDataFailed(dataSearch, errorMessage));
    }
};

export function loadPVDataRequested(dataSearch: DataSearch) {
    return { type: TYPES.LOAD_PV_DATA_REQUESTED, dataSearch };
}

export function loadPVDataSucceeded(dataSearch: DataSearch, rows: Row[], rowConfig: RowConfig[]) {
    return { type: TYPES.LOAD_PV_DATA_SUCCEEDED, dataSearch, rows, rowConfig };
}

export function loadPVDataFailed(dataSearch: DataSearch, errorMessage: string) {
    return { type: TYPES.LOAD_PV_DATA_FAILED, dataSearch, errorMessage };
}

export const updateTag = (updateTagRequest: UpdateTagRequest) => async (dispatch: Dispatch) => {
    console.debug('updateTag.updateTagRequest', updateTagRequest);
    try {
        dispatch(updateTagRequested(updateTagRequest));
        console.debug('updateTag.updateTagRequest', updateTagRequest);

        const response = await admiTaggingService.updateTag(updateTagRequest);
        console.debug('updateTag.response', response);

        dispatch(updateTagSucceeded());
    } catch (e) {
        console.error('updateTag', e);
        let errorMessage = '';
        if (typeof e === 'string') {
            errorMessage = e;
        } else if (e instanceof Error) {
            errorMessage = e.message;
        }
        dispatch(updateTagFailed(errorMessage));
    }
};

export function updateTagRequested(updateTagRequest: UpdateTagRequest) {
    return { type: TYPES.UPDATE_TAG_REQUESTED, updateTagRequest };
}

export function updateTagFailed(errorMessage: string) {
    return { type: TYPES.UPDATE_TAG_FAILED, errorMessage };
}

export function updateTagSucceeded() {
    return { type: TYPES.UPDATE_TAG_SUCCEEDED };
}

export function setFilter(filter: string) {
    return { type: TYPES.SET_FILTER, filter };
}

export function setLocation(location: Location) {
    return { type: TYPES.SET_LOCATION, location };
}

export function setSelectedTag(selectedTag: string) {
    return { type: TYPES.SET_SELECTED_TAG, selectedTag };
}

export function setRowConfig(rowConfig: any) {
    return { type: TYPES.SET_ROW_CONFIG, rowConfig };
}

export function changeRowHighlight(index: number, isHighlighted: boolean) {
    return { type: TYPES.CHANGE_ROW_CONFIG_HIGHLIGHT, index, isHighlighted };
}

export function setErrorMessage(errorMessage: string) {
    return { type: TYPES.SET_ERROR_MESSAGE, errorMessage };
}
