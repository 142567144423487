import { httpClient } from '../../infrastructure/admi-client/http-client';
import { API_BASE_URL } from '../../appConfig';
import { LocationSearch, RealEstate } from './global-tagger.dto';

const ADMI_BASE_URL = API_BASE_URL || 'http://dev.admi.de:8080';

export const admiGlobalTaggerService = {
    async loadRealEstateData(dataSearch: LocationSearch): Promise<RealEstate> {
        const response = await httpClient.get<RealEstate>('/api/v1/buildings', {
            baseURL: ADMI_BASE_URL,
            params: {
                lat: dataSearch.location.lat,
                lng: dataSearch.location.lng,
                radius: dataSearch.radius,
                layers: dataSearch.layers,
            },
        });

        return response.data;
    },
};
