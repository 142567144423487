import SHA256 from 'crypto-js/sha256';
export function hashString(string: string): string {
    const hash = SHA256(string);
    return hash.toString();
}

export function hashStringArray(array: string[]): string {
    return hashString(array.sort((a, b) => a.localeCompare(b)).join(''));

}
